import { Button, Modal, Form, Select, Input, Table, Tabs, Space, Alert, InputNumber } from "antd";
import styles from "../../index.style";
import React, { useState } from "react";
import { PlusOutlined, DeleteOutlined, MinusOutlined } from "@ant-design/icons";
import { userInfoState } from "../../recoil/atoms/userInfoAtom";
import { useRecoilState } from "recoil";
import { Material } from "../../types/material.types";
import type { TabsProps } from "antd";
import { sharedInternalInfoState } from "../../recoil/atoms/sharedInternalAtom";
import MaterialsService from "../../services/materials.service";
import { useTranslation } from "react-i18next";
import { changeShowLoading } from "../../components/Spinner/Spinner";
import { Generic } from "../../types/generic.types";
import NumericPad from "../../components/NumericPad/NumericPad";

const { Search } = Input;

function Materials(): JSX.Element {
  // DEFAULT
  const TAB_MATERIAL = "1";
  const TAB_RECOUNT = "2";
  const LABEL_COL_SPAN = 16;
  const WRAPPER_COL_SPAN = 8;
  const DEFAULT_TABLE_FILTERS = {
    filters: {},
    sorter: {},
    extra: {},
    pagination: {},
  };
  const DEFAULT_ACTIVE_PAGINATION = {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    totalPage: 0,
  };
  // remap dei campi per il sorting a BE (Backend)
  const REMAPPED_FIELDS = {
    "materialInfo": "material_material.code",
    "materialInfoDescription": "material_material.description",
    "measureUnit": "measureUnit.description",
    "quantity": "t.quantity",
    "effectiveQuantity": "t.effectiveQuantity",
  } as Generic;

  const DEFAULT_MATRIX_ARTICLES = {
    notRecount: "",
    recount: "",
  };

  // RECOIL
  const [userInfo, ] = useRecoilState(userInfoState);
  const [sharedInfo] = useRecoilState(sharedInternalInfoState);

  // REACT STATE
  let [tableData, setTableData] = useState<any[]>([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [materialList, setMaterialList] = useState<Material[]>([]);
  const [materialType, setMaterialType] = useState();
  const [searchData, setSearchData] = useState(false);
  const [numberOfMaterialToLoad, setNumberOfMaterialToLoad] = useState(50);
  const [stateKey, setStateKey] = useState(0);
  const [openNumericPad, setOpenNumericPad] = useState(false);
  const [slectedRow, setSelectedRow] = useState<any>(undefined);
  const [currentPadValue, setCurrentPadValue] = useState<string>('0');
  const [tableFilters, setTableFilters] = useState<{
    filters: any;
    sorter: any;
    extra: any;
    pagination: any;
  }>(DEFAULT_TABLE_FILTERS);
  
  const [activePagination, setActivePagination] = useState<{
    current: number,
    pageSize: number, 
    showSizeChanger: boolean,
    totalPage: number,
  }> (DEFAULT_ACTIVE_PAGINATION);

  const [matrixArticles, setMatrixArticles] = useState<{
    notRecount: string;
    recount: string;
  }>(DEFAULT_MATRIX_ARTICLES);

  // FORM & OTHERS
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const columnMaterial = [
    {
      title: `${t("MATERIAL")}`,
      key: "materialInfo",
      render: (text: any, record: any, rowIndex: number) => (
        <div>
          <span className="custom-truncate">
            {record.materialInfo?.code} - {record.materialInfo?.description}
          </span>
        </div>
      ),
    },
    {
      title: `${t("MEASURE_UNIT")}`,
      dataIndex: "mesurationUnit",
      key: "measureUnit",
      sorter: (a: any, b: any) =>
        a.measureUnit?.code.localeCompare(b.measureUnit?.code),
      render: (text: any, record: any, rowIndex: number) => (
        <div>
          <span className="custom-truncate">
            {record.measureUnit?.description}
          </span>
        </div>
      ),
    },
    {
      title: `${t("QUANTITY")}`,
      key: "quantity",
      sorter: (a: any, b: any) => a.quantity - b.quantity,
      render: (text: any, record: any, rowIndex: number) => (
        <div>
          <span className="custom-truncate">{record.quantity}</span>
        </div>
      ),
    },
    {
      title: `${t("EFFECTIVE_QUANTITY")}`,
      dataIndex: "effectiveQuantity",
      key: "effectiveQuantity",
      sorter: (a: any, b: any) => a.effectiveQuantity - b.effectiveQuantity,
      render: (text: any, record: any, rowIndex: number) => (
        <Space style={{ minWidth: "9rem" }}>
          <Button
            type="primary"
            icon={<MinusOutlined />}
            className="customButtonNoFloat"
            onClick={() => changeQuantity(record, "minus", "effectiveQuantity")}
          />
          <Button
            type="primary"
            className="w-20 h-10"
            onClick={() => openNumericPadModal(record)}
          >
            {record.effectiveQuantity}
          </Button>          
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="customButtonNoFloat"
            onClick={() => changeQuantity(record, "plus", "effectiveQuantity")}
          />
        </Space>
      ),
    },
    {
      title: " ",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any, rowIndex: number) => (
        <div>
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            style={{ backgroundColor: styles.red, padding: "7px" }}
            className="customButtonNoFloat"
            onClick={() => deleteMaterial(record)}
          />
        </div>
      ),
    },
  ];

  const columnRecount = [
    {
      title: `${t('MATERIAL')}`,
      key: "materialInfo",
      render: (text: any, record: any, rowIndex: number) => (
        <div>
          <span className="custom-truncate">{record.materialInfo?.code} - {record.materialInfo?.description}</span>
        </div>
      ),
    },
    {
      title: `${t('EFFECTIVE_QUANTITY')}`,
      dataIndex: "effectiveQuantity",
      key: "effectiveQuantity",
    },
    {
      title: `${t('RECOUNT')}`,
      key: "inrecount",
      render: (text: any, record: any, rowIndex: number) => (
        <Space style={{minWidth: '9rem'}}>
          <Button
            type="primary"
            icon={<MinusOutlined />}
            className="customButtonNoFloat"
            onClick={() => changeQuantity(record, "minus", "recount")}
          />
          <Button
            type="primary"
            className="w-20 h-10"
            onClick={() => openNumericPadModal(record)}
          >
            {record.inrecount}
          </Button>          
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="customButtonNoFloat"
            onClick={() => changeQuantity(record, "plus", "recount")}
          />
        </Space>
      ),
    },
    {
      title: `${t('DIFFERENCE')}`,
      dataIndex: "outRecount",
      key: "out",
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `${t('MATERIAL')}`,
      children: (
        <Table
          dataSource={tableData}
          columns={columnMaterial}
          pagination={{ 
            current: activePagination.current,
            pageSize: activePagination.pageSize,
            showSizeChanger: true,
            total: activePagination.totalPage,
          }}
          onChange={(pagination, filters, sorter: any, extra) => { onTableChange(pagination, filters, sorter, extra) }}
        />
      ),
    },
    {
      key: "2",
      label: `${t('RECOUNT')}`,
      children: (
        <Table
          dataSource={tableData}
          columns={columnRecount}
          pagination={{ 
            current: activePagination.current,
            pageSize: activePagination.pageSize,
            showSizeChanger: true,
            total: activePagination.totalPage,
          }}
          onChange={(pagination, filters, sorter: any, extra) => { onTableChange(pagination, filters, sorter, extra) }}
        />
      ),
    },
  ];

  const addMaterial = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setMaterialType(undefined);
  };

  const handleOkModalNumericPad = () => {
    if(activeKey === TAB_MATERIAL){
      slectedRow.effectiveQuantity = parseInt(currentPadValue);
    }
    if(activeKey === TAB_RECOUNT){
      slectedRow.inrecount = parseInt(currentPadValue);
      slectedRow.outRecount = slectedRow.effectiveQuantity - parseInt(currentPadValue);
    }
    updateRow(slectedRow);
    resetModalVariables();
  }

  const resetModalVariables = () => {
    setStateKey(stateKey + 1);
    setSelectedRow(undefined);
    setCurrentPadValue('0');
    setOpenNumericPad(false)
  }

  const handleCancelNumericPad = () => {
    resetModalVariables();
  }

  const resetForm = () => {
    form.resetFields();
  };

  function loadMaterialList(filter: string): void {
    const payload = [
      {
        field: "t.validityEnd",
        operator: "isValid",
      },
      {
        field: "article_type.code",
        operator: "included",
        value: [matrixArticles.notRecount, matrixArticles.recount],
      },
    ];

    const queryparams = {
      page: 1,
      take: filter === '' ? numberOfMaterialToLoad : 50000,
      searchOn: "code,description",
      searchFor: filter,
    };

    changeShowLoading(MaterialsService.getMaterials(payload, queryparams)
      .then((res) => {
        setMaterialList(res.list);
      })
      .catch((err) => {
        console.error(t('ERROR'), err);
      }));
  }

  const loadData = () => {
    let page, pageSize;
    let params = '';

    let payload = [{
      field: 'surgery_management.uuid',
      operator: 'equal',
      value: sharedInfo.surgery,
    }];

    if(activeKey === TAB_RECOUNT){ // se sto chiamando la tab del riconteggio 
      payload.push({
        field: "material_material_type.code",
        operator: "equal",
        value: matrixArticles.recount,
      });

      payload.push({
        field: 't.effectiveQuantity',
        operator: 'gtEqual',
        value: "1",
      });
    }

    page = tableFilters?.pagination?.current || activePagination.current;
    pageSize = tableFilters?.pagination?.pageSize || activePagination.pageSize;

    if(searchFilter){
      params += `&searchOn=material_material.description,material_material.code&searchFor=${searchFilter}`; 
    }
    
    if(tableFilters.sorter && tableFilters.sorter.columnKey && tableFilters.sorter.order){
      params += `&order=${REMAPPED_FIELDS[tableFilters.sorter.columnKey]}:${tableFilters.sorter.order === 'ascend' ? 'ASC' : 'DESC'}`;
    }else{
      // order for description by default
      params += `&order=${REMAPPED_FIELDS['materialInfoDescription']}:ASC`;
    }

    changeShowLoading(MaterialsService.loadData(payload, page, pageSize, params)
      .then((res) => {
        if(activeKey === TAB_RECOUNT) {
          res.list = estimateDifference(res.list);
        }
        setTableData(res.list);
        setActivePagination({
          current: parseInt(res.page),
          pageSize: parseInt(res.pageSize),
          showSizeChanger: true,
          totalPage: res.totalResults,
        });
      })
      .catch((err) => {
        console.error(err);
      }))
      .finally(() => {
        setSearchData(false);
      });
  };

  const estimateDifference = (data: any) => {
    (data || []).forEach((element: any) => {
      element.outRecount = element.effectiveQuantity - element.inrecount;
    });

    return data;
  }  

  const onTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log("Tab has changed", pagination, filters, sorter, extra);
    setTableFilters({ filters, sorter, extra, pagination });
  };

  const deleteMaterial = (record: any) => {  
    if(userInfo.capabilities["CAN_EDIT_ALL_TAB"] || userInfo.capabilities["CAN_EDIT_MATERIALS_TAB"]){
      changeShowLoading(MaterialsService.deleteMaterial(record.uuid)
        .then(() => {
          loadData();
        })
        .catch((err) => {
          console.error(err);
        }));
    }else{
      <Alert message={t('PERMISSION_NEGATE')} type="warning" showIcon closable />
    }
  };

  const onTabChange = (activeKey: string) => {
    setTableData([]);
    resetActivePagination();
    resetTableFilters();
    setActiveKey(activeKey);
  }

  const resetActivePagination = () => {
    setActivePagination(DEFAULT_ACTIVE_PAGINATION);
  };

  const resetTableFilters = () => {
    setTableFilters({
      filters: {},
      sorter: {},
      extra: {},
      pagination: {},
    });
  };

  const setManualEffectiveQty = (e: any, record: any) => {
    // check if the value is a number using regex
    if (!/^[0-9]*$/.test(e.target.value) || !e.target.value) {
      return;
    }

    let manualQty = e.target?.value ? parseInt(e.target.value) : 0;
    let indexEl = tableData.findIndex((item) => item.uuid === record.uuid);

    if (manualQty < 0) {
      return;
    }

    tableData[indexEl].effectiveQuantity = manualQty;
    refreshTableData(tableData);
  };

  const refreshTableData = (data: any) => {
    tableData = [...data];
    setTableData(tableData);
  };

  const updateRow = (record: any) => {
    let indexEl = tableData.findIndex((item) => item.uuid === record.uuid);
    changeShowLoading(
      MaterialsService.update(tableData[indexEl].uuid, tableData[indexEl])
        .then(() => {
          refreshTableData(tableData);
        })
        .catch((err) => {
          console.error(err);
        })
    );
  };

  const changeQuantity = (record: any, operation: string, type: string) => {
    let count = 0;

    if (!userInfo.capabilities["CAN_EDIT_ALL_TAB"] || !userInfo.capabilities["CAN_EDIT_MATERIALS_TAB"]) {
      <Alert message={t("PERMISSION_NEGATE")} type="warning" showIcon closable />;
      return;
    }

    if (operation === "plus") count++;
    if (operation === "minus") count--;

    let indexEl = tableData.findIndex((item) => item.uuid === record.uuid);

    if(indexEl === -1) return; // se non trovo l'elemento esco

    if (type === "effectiveQuantity" && ((tableData[indexEl].effectiveQuantity >= 0 && count === 1) || tableData[indexEl].effectiveQuantity > 0)) {
      tableData[indexEl].effectiveQuantity = tableData[indexEl].effectiveQuantity + count;
    } else if (type === "recount" && ((tableData[indexEl].inrecount >= 0 && count === 1) || tableData[indexEl].inrecount > 0)) {
      tableData[indexEl].inrecount = tableData[indexEl].inrecount + count;
      tableData[indexEl].outRecount = tableData[indexEl].effectiveQuantity - tableData[indexEl].inrecount;
    }

    refreshTableData(tableData);
    updateRow(record);
  };

  const saveNewElement = async (element: any) => {
    const isValid = await form.validateFields().catch(() => false);
    if (!isValid) return;

    if(userInfo.capabilities["CAN_EDIT_ALL_TAB"] || userInfo.capabilities["CAN_EDIT_MATERIALS_TAB"]){
      changeShowLoading(MaterialsService.createMaterial(element)
        .then(() => {
          setIsModalOpen(false);
        })
        .catch((err) => {
          console.error(err);
        }));
    }else{
      <Alert message={t('PERMISSION_NEGATE')} type="warning" showIcon closable />
    }
  };

  const handleOk = () => {
    let data = form.getFieldsValue();
    data = {
      uuid: sharedInfo.surgery,
      inrecount: 0,
      material: data.material,
      quantity: 1,
      effectiveQuantity: parseInt(data.quantity) ? parseInt(data.quantity) : 0,
      outRecount: 0,
    };

    saveNewElement(data);
  };

  const onSearch = (value: any) => {
    // svuoto i filtri e la paginazione attiva
    resetActivePagination();
    resetTableFilters();
    // imposto il nuovo valore di ricerca
    setSearchFilter(value);
    setSearchData(true); 
  };

  const onScroll = (value: any) => {
    const target = value.target
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setNumberOfMaterialToLoad(numberOfMaterialToLoad + 50);
    }  
  }

  const onSearchValue = (value: any) => {
    loadMaterialList(value);
  }

  const loadMatrixValue = () => {
    let matrix = userInfo.matrixPar["system.articles"];
    try {
      let info = JSON.parse(matrix);

      info = {
        notRecount: info.notRecount,
        recount: info.recount,
      };

      setMatrixArticles(info);
    } catch (error) {
      console.log(error);
    }
  };

  const onPadChange = (value: string) => {
    setCurrentPadValue(value);
  };

  const openNumericPadModal = (record: any) => {
    if (!userInfo.capabilities["CAN_EDIT_ALL_TAB"] || !userInfo.capabilities["CAN_EDIT_MATERIALS_TAB"]) {
      <Alert message={t("PERMISSION_NEGATE")} type="warning" showIcon closable />;
      return;
    }
    if(record){
      setSelectedRow(record);
      if(record.effectiveQuantity) {
        setCurrentPadValue(record.effectiveQuantity+'');
      }
      setOpenNumericPad(true);
    }
  }

  // USE EFFECTS
  React.useEffect(() => {
    loadMatrixValue();
  }, []);

  React.useEffect(() => {
    loadData();
  }, [matrixArticles, activeKey, searchFilter, tableFilters, isModalOpen === false]);

  React.useEffect(() => {
    if (isModalOpen) loadMaterialList("");
    if (!isModalOpen) resetForm();
  }, [isModalOpen]);

  // RENDER
  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        closable={false}
        maskClosable={false}
        okButtonProps={{ disabled: materialType ? false : true }}
      >
        <div className="form-container">
          <Form 
            name="normal_login" 
            className="login-form" 
            form={form}
            layout="horizontal" 
            labelCol={{
              span: 7,
            }}
            wrapperCol={{
              span: 20,
            }}
          >
            <Form.Item
              name="material"
              className="form-container-item"
              label={t("MATERIAL")}
              rules={[
                {
                  required: true,
                  message: `${t("REQUIRED_FIELD")}`,
                },
              ]}
            >
              <Select
                showSearch
                className="w-full mt-4"
                placeholder={t("SELECT_MATERIAL")}
                optionFilterProp="children"
                fieldNames={{ label: "description", value: "uuid" }}
                onChange={(e) => setMaterialType(e)}
                onPopupScroll={onScroll}
                onSearch={onSearchValue}
                filterOption={(input, option: any) =>
                  option.description
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                options={materialList}
              />
            </Form.Item>

            <Form.Item
              name="quantity"
              className="form-container-item"
              label={t("EFFECTIVE_QUANTITY")}
            >
              <InputNumber
                size="large"
                type="number"
                stringMode
                min="1"
                defaultValue="1"
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Space align="end" className="pullRight">
        <Search
          placeholder={`${t("MATERIAL_FILTER")}`}
          onSearch={onSearch}
          enterButton
          style={{ width: 304 }}
          loading={searchData}
          allowClear
        />

        <Button
          type="primary"
          className="customButton"
          style={{ backgroundColor: styles.orange }}
          onClick={addMaterial}
        >
          <PlusOutlined />
        </Button>
      </Space>

      <Modal
         open={openNumericPad}
         onCancel={handleCancelNumericPad}
         onOk={handleOkModalNumericPad}
         closable={false}
         maskClosable={false}
      > 
      {activeKey === TAB_MATERIAL ? <>
        <NumericPad key={stateKey} onResultChange={onPadChange} value={slectedRow?.effectiveQuantity + ''}/>
      </> : ''}

      {activeKey === TAB_RECOUNT ? <>
        <NumericPad key={stateKey} onResultChange={onPadChange} value={slectedRow?.inrecount + ''}/>
      </> : ''}
        
      </Modal>

      <Tabs
        onChange={(activeKey: string) => onTabChange(activeKey)}
        defaultActiveKey={TAB_MATERIAL}
        items={items}
      />
    </>
  );
}

export default Materials;
