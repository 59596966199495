// db.ts
import Dexie, { Table } from 'dexie';
import { SettingsData } from '../types/Setting.types';
import { DBTables } from '../enums/db.enum';

const DB_SCHEMAS = {
  [DBTables.SETTINGS]: 'key', // Primary key and indexed props
};
export class SixStorage extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  [DBTables.SETTINGS]!: Table<SettingsData>; //settings table

  constructor() {
    super('sisos_monitor_db');
    this.version(40).stores(DB_SCHEMAS); // every time you change the schema, you need to bump the new version number
  }

  // this method drop all tables from indexedDB
  async deleteAllTables() {
    await this.delete();
  }

  // drop specific table from indexedDB
  async deleteTable(tableName: string) {
    await this.table(tableName).clear();
  }
}



export const db = new SixStorage();