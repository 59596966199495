import "./Checklist.css";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Form, Input, Modal, Radio, Select, Steps, Alert } from "antd";
import ChecklistService from "../../services/checklist.service";
import { useRecoilState, useRecoilValue } from "recoil";
import { getUserInfoState } from "../../recoil/atoms/userInfoAtom";
import { DateTime } from "luxon";
import { ChecklistTab } from "../../enums/checklist.enum";
import TimePicker from "../../components/TimePicker/TimePicker";
import { sharedInternalInfoState } from "../../recoil/atoms/sharedInternalAtom";
import { SurgeryManagementStatus } from "../../enums/surgeryManagementStatus.enum";
import WrapperService from "../../services/wrapper.service";
import { getPatientInfoState } from "../../recoil/atoms/patientInfoAtom";
import { userInfoState } from "../../recoil/atoms/userInfoAtom";
import { useTranslation } from "react-i18next";
import { checklistPhase } from "../../recoil/atoms/checklistPhase";
import { changeShowLoading } from "../../components/Spinner/Spinner";
import { ChecklistPhase } from "../../types/wrapper.types";

function Checklist() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { type } = useParams();
  const [form] = Form.useForm();

  const [userInfoCapabilites, setUserInfoCapabilites] =
    useRecoilState(userInfoState);
  const [stepsPhase, setStepPhase] = useRecoilState(checklistPhase);
  const [tabName, setTabName] = useState("");
  const [capabilitiesState, setCapabilitesValue] = useState(false);
  const [lastRevision, setLastRevision] = useState([] as any);
  const [checklistTab, setChecklistTab] = useState([{ title: "" }]);
  const [currentStep, setCurrentStep] = useState(0);
  const [step, setStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userInfo } = useRecoilValue(getUserInfoState);
  const [startTime, setStartTime] = useState("");
  const patientInfo = useRecoilValue(getPatientInfoState);
  const [surgeryInfoState, setSurgeryInfoState] = useState(false);

  const [sharedInfo, setSharedInfo] = useRecoilState(sharedInternalInfoState);

  const labelColSpan = 16;
  const wrapperColSpan = 8;

  /**********USE EFFECTS**********/
  React.useEffect(() => {
    if(surgeryInfoState){
      loadData();
    }
  }, [type, currentStep, surgeryInfoState]);

  React.useEffect(() => {
    if (lastRevision.length > 0) {
      restoreForm();
    }
  }, [lastRevision]);

  React.useEffect(() => {
    setStep(0);
  },[location.state.phaseName]);

  React.useEffect(() => {
    let allSurgery = stepsPhase;
    let currentUuid = sharedInfo.surgery;

    let objExist = allSurgery.filter((el) => {
      if(el.uuid === currentUuid){
        return true;
      }
    });

    if(objExist.length === 0){
      let newList = [
        ...allSurgery,
        {
          uuid: currentUuid,
          po: 0,
          ca: 0,
          ci: 0,
          pa: 0
        }
      ];

      setStepPhase(newList)
    }

    setSurgeryInfoState(true);
  }, []);
  /**********HOOKS**********/
  const loadData = async () => {
    if (!type) {
      console.error(t("CAN_NOT_LOAD_CHECKLIST_WHIT_OUT_TYPE"));
      return;
    }

    const tabName = chooseTabName(type);
    setTabName(type);
    getStep();

    const payload = {
      uuid: location.state.uuid,
      tabName: tabName,
    };

    let result;
    await changeShowLoading(
      ChecklistService.load(payload).then((res) => {
        result = res;
      })
    );
    let checklistTab = setListType(result, type);
    setChecklistTab(checklistTab);
  };

  const getStep = () => {
    if (!type) {
      console.error(t("CAN_NOT_LOAD_CHECKLIST_WHIT_OUT_TYPE"));
      return;
    }

    let position = getPosition();

    switch (type.toUpperCase()) {
      case "PO":
        setCurrentStep(stepsPhase[position].po);
        break;
      case "CA":
        setCurrentStep(stepsPhase[position].ca);
        break;
      case "CI":
        setCurrentStep(stepsPhase[position].ci);
        break;
      case "PA":
        setCurrentStep(stepsPhase[position].pa);
        break;
    }
  };

  const chooseTabName = (type: string) => {
    switch (type.toUpperCase()) {
      case "PO":
        setCapabilitesValue(
          userInfoCapabilites.capabilities["CAN_EDIT_PO_TAB"]
        );
        return ChecklistTab.PO;
      case "CA":
        setCapabilitesValue(
          userInfoCapabilites.capabilities["CAN_EDIT_CA_TAB"]
        );
        return ChecklistTab.CA;
      case "CI":
        setCapabilitesValue(
          userInfoCapabilites.capabilities["CAN_EDIT_CI_TAB"]
        );
        return ChecklistTab.CI;
      case "PA":
        return ChecklistTab.PA;
      default:
        console.log("No tab name found");
        return "";
    }
  };

  const restoreForm = () => {
    const checkpoints = lastRevision[currentStep]?.checkpoints || [];
    if(!checkpoints.length){
      console.error("Empty Checkpoints");
    }

    let formData = {} as any;
    for (const cp of checkpoints) {
      if (cp.checkpointInfo.type === "timer") {
        cp.submittedAnswers = cp.submittedAnswers
          ? DateTime.fromISO(cp.submittedAnswers, {
              zone: "Europe/Rome", //TODO: Da scodare
            })
          : null;
      }
      if (cp.submittedAnswers) {
        formData[cp.checkpointInfo.uuid] = cp.submittedAnswers;
      }
    }
    form.setFieldsValue({ ...formData }); // restore form data
  };

  const changeStep = (step: number) => {
    setStep(step);
    setIsModalOpen(true);
  };

  const onValuesChange = () => {
    if (sharedInfo.status === SurgeryManagementStatus.CONFIRMED) {
      changeShowLoading(
        WrapperService.startSurgery(location.state.uuid, location.state.patient)
          .then(() => {
            setSharedInfo({
              ...sharedInfo,
              status: SurgeryManagementStatus.ONGOING,
            });
          })
          .catch((error) => {
            console.log(error);
          })
      );
    }
    if (!startTime) {
      setStartTime(DateTime.local().toFormat("yyyy-MM-dd HH:mm:ss"));
    }
  };

  const controlPhase = (checklist: any) => {  
    const lastRevisionAnswers = lastRevision[currentStep].checkpoints.map((el: any) => {
      return el.submittedAnswers;
    });

    const checklistAnswer = checklist.map((el: any) => {
      return el.submittedAnswers;
    });

    return JSON.stringify(checklistAnswer) === JSON.stringify(lastRevisionAnswers) ? lastRevision[currentStep].endTime : DateTime.local().toFormat("yyyy-MM-dd HH:mm:ss");
  }

  const handleOk = async () => {
    if (!type) {
      console.error(t("CAN_NOT_LOAD_CHECKLIST_WHIT_OUT_TYPE"));
      return;
    }

    setIsModalOpen(false);

    if (
      userInfoCapabilites.capabilities["CAN_EDIT_ALL_TAB"] ||
      capabilitiesState
    ) {
      const formItems = form.getFieldsValue();

      // validate form and show required fields (return boolean)
      const isValid = await form.validateFields().catch((error) => {
        console.log(error);
        return false;
      });

      if (!isValid) {
        return;
      }

      const selectedChecklist = lastRevision[currentStep];
      //FIXME //TODO
      const checkpoints = (selectedChecklist?.checkpoints || []).map(
        (cp: any) => {
          //FIXME //TODO
          return {
            checkpoint: cp.checkpointInfo.uuid,
            type: cp.checkpointInfo.uuid,
            question: cp.checkpointInfo.question,
            submittedAnswers: formItems[cp.checkpointInfo.uuid],
          };
        }
      );

      const payload = {
        checklist: selectedChecklist.checklist.uuid,
        startTime: selectedChecklist.startTime
          ? selectedChecklist.startTime
          : DateTime.local().toFormat("yyyy-MM-dd HH:mm:ss"),
        endTime: controlPhase(checkpoints),
        startOperator: userInfo.operatorUuid,
        endOperator: userInfo.operatorUuid,
        completed: selectedChecklist.completed,
        checkpoints: checkpoints,
      };

      const tabName = chooseTabName(type);

      await changeShowLoading(
        ChecklistService.update(location.state.uuid, {
          tabName: tabName,
          content: payload,
        }).catch((error) => {
          console.log(error);
        })
      );

      setCurrentStep(step);
      setStepPhaseValue(step);
    } else {
      <Alert
        message="Permesso non abilitato"
        type="warning"
        showIcon
        closable
      />;
    }
  };

  const setStepPhaseValue = (step: number) => {
    let value = [ ...stepsPhase];
    let position = getPosition();

    value[position] = {...value[position]};

    switch (tabName.toUpperCase()) {
      case "PO":
        value[position].po = step;
        setStepPhase(value);
        break;
      case "CA":
        value[position]["ca"] = step;
        setStepPhase(value);
        break;
      case "CI":
        value[position]["ci"] = step;
        setStepPhase(value);
        break;
      case "PA":
        value[position]["pa"] = step;
        setStepPhase(value);
        break;
    }
  };

  const getPosition = () => {
    let value = [...stepsPhase];
    let position = 0;
    let positionReturn = 0;

    value.map((el: ChecklistPhase) => {
      if(el.uuid !== sharedInfo?.surgery){
        position++;
      }else{
        positionReturn = position;
      }
    });

    return positionReturn;
  }

  const setNameStep = (step: number) => {
    return checklistTab[step]?.title || "";
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const setListType = (totalList: any, type: any) => {
    let checklistTab = [];
    switch (type.toUpperCase()) {
      case "PO":
        setLastRevision(totalList.poChecklists);
        checklistTab = (totalList.poChecklists || []).map((el: any) => {
          return {
            title: el.checklist.description,
          };
        });
        break;
      case "CA":
        setLastRevision(totalList.caChecklists);
        checklistTab = (totalList.caChecklists || []).map((el: any) => {
          return {
            title: el.checklist.description,
          };
        });
        break;
      case "CI":
        setLastRevision(totalList.ciChecklists);
        checklistTab = (totalList.ciChecklists || []).map((el: any) => {
          return {
            title: el.checklist.description,
          };
        });
        break;
      case "PA":
        setLastRevision(totalList.paChecklists);
        checklistTab = (totalList.paChecklists || []).map((el: any) => {
          return {
            title: el.checklist.description,
          };
        });
        break;
    }

    return checklistTab;
  };

  const startAction = () => {
    const currentStatus = sharedInfo.status;
    if (currentStatus === "confirmed") {
      changeShowLoading(
        WrapperService.startSurgery(sharedInfo.surgery, patientInfo.uuid)
          .then(() => {
            setSharedInfo({
              ...sharedInfo,
              status: SurgeryManagementStatus.ONGOING,
            });
          })
          .catch((error) => {
            console.log(error);
          })
      );
    }
    handleOk();
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        closable={false}
      >
        <p>
          {t("DO_YOU_WANT_TO_FINISH")} {setNameStep(currentStep)}
          {t("AND_START_THE_PHASE_OF")} {setNameStep(step)}?
        </p>
      </Modal>
      <Steps
        type="navigation"
        size="small"
        current={currentStep}
        onChange={(step) => {
          changeStep(step);
        }}
        items={checklistTab}
      />

      <div className="form-container">
        {lastRevision ? (
          lastRevision[currentStep]?.checkpoints.map(
            (cp: any, index: number) => {
              return (
                <Form
                  name="normal_login"
                  className="login-form"
                  form={form}
                  onValuesChange={onValuesChange}
                >
                  {cp.checkpointInfo.type === "boolean" ? (
                    <Form.Item
                      name={cp.checkpointInfo.uuid}
                      label={cp.question}
                      className="form-container-item"
                      labelCol={{
                        span: labelColSpan,
                      }}
                      wrapperCol={{
                        span: wrapperColSpan,
                      }}
                      rules={[
                        {
                          required: cp.checkpointInfo.mandatory,
                          message: `${t("REQUIRED_FIELD")}`,
                        },
                      ]}
                    >
                      <Radio.Group
                        className="flex justify-end form-container-radio"
                        optionType="button"
                        buttonStyle="solid"
                        size="large"
                        onChange={startAction}
                        defaultValue={cp.submittedAnswers}
                        value={cp.submittedAnswers}
                      >
                        <Radio
                          value={cp.submittedAnswers === true ? true : "true"}
                        >
                          {" "}
                          {t("YES")}{" "}
                        </Radio>
                        <Radio
                          value={
                            cp.submittedAnswers === false ? false : "false"
                          }
                        >
                          {" "}
                          {t("NO")}{" "}
                        </Radio>
                        <Radio
                          value={cp.submittedAnswers === null ? null : "nd"}
                        >
                          {" "}
                          {t("NOT_DEFINED")}{" "}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  ) : null}

                  {cp.checkpointInfo.type === "text" ? (
                    <Form.Item
                      name={cp.checkpointInfo.uuid}
                      label={cp.question}
                      className="form-container-item"
                      labelCol={{
                        span: labelColSpan,
                      }}
                      wrapperCol={{
                        span: wrapperColSpan,
                      }}
                      rules={[
                        {
                          required: cp.checkpointInfo.mandatory,
                          message: `${t("REQUIRED_FIELD")}`,
                        },
                      ]}
                    >
                      <Input.TextArea onBlurCapture={startAction} />
                    </Form.Item>
                  ) : null}

                  {cp.checkpointInfo.type === "dropdown" ? (
                    <Form.Item
                      name={cp.checkpointInfo.uuid}
                      className="form-container-item"
                      label={cp.question}
                      labelCol={{
                        span: labelColSpan,
                      }}
                      wrapperCol={{
                        span: wrapperColSpan,
                      }}
                      rules={[
                        {
                          required: cp.checkpointInfo.mandatory,
                          message: `${t("REQUIRED_FIELD")}`,
                        },
                      ]}
                    >
                      <Select
                        className="w-full"
                        optionFilterProp="children"
                        fieldNames={{
                          label: "description",
                          value: "key",
                        }}
                        options={cp.checkpointInfo.answers}
                        onChange={startAction}
                        filterOption={(input, option: any) =>
                          option.description
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    </Form.Item>
                  ) : null}

                  {cp.checkpointInfo.type === "multiple" ? (
                    <Form.Item
                      name={cp.checkpointInfo.uuid}
                      className="form-container-item"
                      label={cp.question}
                      labelCol={{
                        span: labelColSpan,
                      }}
                      wrapperCol={{
                        span: wrapperColSpan,
                      }}
                      rules={[
                        {
                          required: cp.checkpointInfo.mandatory,
                          message: `${t("REQUIRED_FIELD")}`,
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        className="w-full"
                        optionFilterProp="children"
                        fieldNames={{
                          label: "description",
                          value: "key",
                        }}
                        options={cp.checkpointInfo.answers}
                        onChange={startAction}
                        filterOption={(input, option: any) =>
                          option.description
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    </Form.Item>
                  ) : null}

                  {cp.checkpointInfo.type === "timer" ? (
                    <Form.Item
                      name={cp.checkpointInfo.uuid}
                      className="form-container-item"
                      label={cp.question}
                      labelCol={{
                        span: labelColSpan,
                      }}
                      wrapperCol={{
                        span: wrapperColSpan,
                      }}
                      rules={[
                        {
                          required: cp.checkpointInfo.mandatory,
                          message: `${t("REQUIRED_FIELD")}`,
                        },
                      ]}
                    >
                      <TimePicker
                        format="HH:mm:ss"
                        onChange={startAction}
                        changeOnBlur
                        onSelect={(e) => console.log(e)}
                      />
                    </Form.Item>
                  ) : null}
                </Form>
              );
            }
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Checklist;
