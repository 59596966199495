import { AbnormalFilter } from "../types/common.types";
import { InfusionPayload } from "../types/infusions.type";
import { baseHttp } from "./baseHttp";

const loadData = async (payload: { uuid: string; tabName: string }) => {
  return baseHttp
    .post(`/surgery-management-revision/last/revision/monitor`, payload)
    .then((response: any) => response.data);
};

const update = async (
  surgeryUUID: string,
  payload: {
    tabName: string;
    content: Array<InfusionPayload>;
  }
) => {
  return baseHttp
    .post(`/surgery-management/monitor/update/${surgeryUUID}`, payload)
    .then((response: any) => response.data);
};

const getDrugsTypes = async (
  payload: AbnormalFilter[],
  queryparams = {
    page: 1,
    take: 50,
    searchOn: "code,description",
    searchFor: "",
  }
) => {
  // post
  return baseHttp
    .post(`/drug-types/filter`, payload, { params: queryparams })
    .then((response: any) => response.data);
};

const getDrugs = async (
  payload: AbnormalFilter[],
  queryparams = {
    page: 1,
    take: 50,
    searchOn: "code,description",
    searchFor: "",
  }
) => {
  // post
  return baseHttp
    .post(`/drugs/filter`, payload, { params: queryparams })
    .then((response: any) => response.data);
};

const getMeasureUnit = async (
  payload: AbnormalFilter[],
  queryparams = {
    page: 1,
    take: 50,
    searchOn: "code,description",
    searchFor: "",
  }
) => {
  // post
  return baseHttp
    .post(`/measure-unit/filter`, payload, { params: queryparams })
    .then((response: any) => response.data);
};

const InfusionService = {
  loadData,
  update,
  getDrugsTypes,
  getDrugs,
  getMeasureUnit,
};

export default InfusionService;
