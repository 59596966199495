import { Button, Row, Typography } from "antd";
import Calendar from "../../components/Calendar/LuxonCalendar";
import "./Calendar.css";
import React, { useEffect, useState } from "react";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import styles from "../../index.style";
import CalendarService from "../../services/calendar.service";
import CookieService from "../../services/cookie.service";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import {
  CalendarRenderCell,
  SurgeriesCalendarResponse,
} from "../../types/calendar.types";
import type { CellRenderInfo } from "rc-picker/lib/interface";
import { useTranslation } from "react-i18next";
import { changeShowLoading } from "../../components/Spinner/Spinner";

const { Title } = Typography;

function CalendarView() {
  let navigate = useNavigate();
  const [calendarEvents, setCalendarEvents] = useState(
    {} as SurgeriesCalendarResponse
  );
  const { t, i18n } = useTranslation();
  const [currentDate, setCurrentData] = useState(DateTime.now().toFormat("yyyy/MM"));
  const [calendarMonth, setCalendarMonth] = useState("");

  const getCalendarCountEvents = (
    firstDayOfThisMonth: string,
    lastDayOfThisMonth: string
  ): void => {
    const payload = [
      { field: "t.validityEnd", value: "undefined", operator: "isValid" },
      {
        field: "data.admission_date",
        operator: "isBetween",
        value: [firstDayOfThisMonth, lastDayOfThisMonth],
        usecase: "date",
      },
      {
        field: "data.surgeryroom_uuid",
        value: CookieService.getSurgeryRoomUUID(),
        operator: "equal",
      },
    ];
    changeShowLoading(CalendarService.retrieveSurgeriesCalendarInfo(payload).then((res) => {
      setCalendarEvents(res);
    }));
  };

  useEffect(() => {
    const firstDayOfThisMonth = DateTime.local()
      .startOf("month")
      .toFormat("yyyy-MM-dd");
    const lastDayOfThisMonth = DateTime.local()
      .endOf("month")
      .toFormat("yyyy-MM-dd");
    setCalendarMonth(firstDayOfThisMonth);

    getCalendarCountEvents(firstDayOfThisMonth, lastDayOfThisMonth);
  }, []);

  const getListData = (value: any): CalendarRenderCell => {
    const currDate = value.toFormat("yyyy-MM-dd");
    if (calendarEvents && calendarEvents[currDate]) {
      return {
        type: "success",
        content: calendarEvents[currDate],
        currentDate: value,
      };
    }
    return { type: "false", content: 0, currentDate: value };
  };

  const dateCellRender = (value: any) => {
    const listData: CalendarRenderCell = getListData(value);
    if (listData.type !== "false") {
      return (
        <Button
          className="day-calendar-event"
          type="primary"
          shape="round"
          style={{ backgroundColor: styles.lightBlueBgColor }}
          onClick={() => onSelectDay(listData.currentDate)}
        >
          {listData.content} {t('INTERVENTIONS')}
        </Button>
      );
    }
  };

  const cellRender = (current: any, info: CellRenderInfo<any>) => {
    if (info.type === "date") return dateCellRender(current);
    // if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };

  const changeMonthCalendar = (action: string) => {
    const currentMonth = DateTime.fromFormat(calendarMonth, "yyyy-MM-dd");
    let newMonth;
    if (action === "minus") {
      newMonth = currentMonth.minus({ months: 1 });
    } else if (action === "plus") {
      newMonth = currentMonth.plus({ months: 1 });
    } else {
      newMonth = DateTime.local();
    }

    const firstDayOfThisMonth = newMonth
      .startOf("month")
      .toFormat("yyyy-MM-dd");
    const lastDayOfThisMonth = newMonth.endOf("month").toFormat("yyyy-MM-dd");
    setCalendarMonth(firstDayOfThisMonth);
    getCalendarCountEvents(firstDayOfThisMonth, lastDayOfThisMonth);
    return newMonth;
  };

  const onPanelChange = (newValue: any) => {
    let month = newValue.c.month > 10 ? ''+newValue.c.month : '0'+newValue.c.month;

    let value = newValue.c.year +'/'+ month;
    setCurrentData(value);
  };

  const onSelectDay = (newValue: any): void => {
    navigate("/surgeries", {
      state: { date: newValue.toFormat("yyyy-MM-dd") },
    });
  };

  return (
    <>
      <div className="px-10" style={{ paddingTop: "18px", height: '80vh'}}>
        <Calendar
          mode="month"
          headerRender={({ value, type, onChange, onTypeChange }) => {
            return (
              <Row justify="space-between" className="pr-10 pt-4 m-4">
                <div>
                  <Button
                    type="primary"
                    className="customButton"
                    style={{ backgroundColor: styles.orange }}
                    onClick={() => navigate("/surgeries")}
                  >
                    X
                  </Button>
                </div>
                <div>
                <Title level={4} className="ant-table-cell">
                  {currentDate}
                </Title>
                </div>
                <div className="flex justify-between">
                  <Button
                    type="primary"
                    className="customButton mr-8"
                    style={{ backgroundColor: styles.lightBlueBgColor }}
                    onClick={async () => {
                      const newDate = await changeMonthCalendar("tooday");
                      onChange(newDate);
                    }}
                  >
                    {t('TODAY')}
                  </Button>
                  <Button
                    type="primary"
                    icon={<LeftOutlined />}
                    size={"large"}
                    className="customButton mr-4"
                    style={{ backgroundColor: styles.blueColor }}
                    onClick={async () => {
                      const newDate = await changeMonthCalendar("minus");
                      onChange(newDate);
                    }}
                  />
                  <Button
                    type="primary"
                    icon={<RightOutlined />}
                    size={"large"}
                    className="customButton"
                    style={{ backgroundColor: styles.blueColor }}
                    onClick={async () => {
                      const newDate = await changeMonthCalendar("plus");
                      onChange(newDate);
                    }}
                  />
                </div>
              </Row>
            );
          }}
          onSelect={onPanelChange}
          onPanelChange={onPanelChange}
          cellRender={cellRender}
        />
      </div>
    </>
  );
}

export default CalendarView;
