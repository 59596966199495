import { baseHttp } from "./baseHttp";

const loadData = async (payload: { uuid: string; tabName: string }) => {
  return baseHttp
    .post(`/surgery-management-revision/last/revision/monitor`, payload)
    .then((response: any) => response.data);
};



const update = async (surgeryUUID: string, payload: {
    tabName: string;
    content: {
      checklist: string;
      checkpoints: Array<{
        checkpoint: string; 
        type: string; 
        question: string;
        submittedAnswers: string | Array<string>;
      }>;
    }
  }) => {
    return baseHttp
      .post(`/surgery-management/monitor/update/${surgeryUUID}`, payload)
      .then((response: any) => response.data);
  };

const AnesthesispathService = {
  loadData,
  update,
};

export default AnesthesispathService;
