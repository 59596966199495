const styles = {
    blueColor: '#005a76',
    lightBlueBgColor: '#029b9a',
    greyColor: '#E9EDF1',
    orange: '#ff7222',
    badgeLightBlueBg: '#D9E7EB',
    yellow: '#FEE8D9',
    pink: '#F4DEFB',
    red: '#ff0000',
    green: '#00ff00'
};

export default styles