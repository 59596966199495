import ParentWrapperView from "../components/Wrapper/Wrapper";
import { RouterType } from "../types/router.types";
import Login from "./Login/Login";
import Surgeries from "./Surgeries/Surgeries";
import CalendarView from "./Calendar/Calendar";
import Checklist from "./Checklist/Checklist";
import Equipe from "./Equipe/Equipe";
import Materials from "./Materials/Materials";
import Implantables from "./Implantables/Implantables";
import Infusions from "./Infusions/Infusions";
import Anesthesispath from "./Anesthesispath/Anesthesispath";
import Readings from "./Readings/Readings";
import Timers from "./Timer/Timer";
import Settings from "./Settings/Settings";
import RedirectHandler from "./RedirectHandler/RedirectHandler";
import Maintenance from "./Maintenance/Maintenance";

const pagesData: RouterType[] = [
  {
    path: "*",
    element: <RedirectHandler />,
    title: "Loading...",
  },
  {
    path: "/maintenance/:code",
    element: <Maintenance />,
    title: "Maintenance",
  },
  {
    path: "/login",
    element: <Login />,
    title: "login",
  },
  {
    path: "/settings",
    element: <Settings/>,
    title: "settings",
  },
  {
    path: "/login",
    element: <Login />,
    title: "login",
  },
  {
    path: "/surgeries",
    element: (
      <ParentWrapperView title="Surgeries" sidebar="false">
        <Surgeries />
      </ParentWrapperView>
    ),
    title: "Surgeries",
  },
  {
    path: "/calendar",
    element: (
      <ParentWrapperView title="Calendar" sidebar="false">
        <CalendarView />
      </ParentWrapperView>
    ),
    title: "Calendar",
  },
  {
    path: "/checklist/:type",
    element: (
      <ParentWrapperView title="Checklist" sidebar="true">
        <Checklist />
      </ParentWrapperView>
    ),
    title: "Checklist",
  },
  {
    path: "/equipe",
    element: (
      <ParentWrapperView title="equipe" sidebar="true">
        <Equipe />
      </ParentWrapperView>
    ),
    title: "Equipe",
  },
  {
    path: "/materials",
    element: (
      <ParentWrapperView title="materials" sidebar="true">
        <Materials />
      </ParentWrapperView>
    ),
    title: "Materials",
  },
  {
    path: "/implantables",
    element: (
      <ParentWrapperView title="Implantables" sidebar="true">
        <Implantables />
      </ParentWrapperView>
    ),
    title: "Implantables",
  },
  {
    path: "/readings",
    element: (
      <ParentWrapperView title="Readings" sidebar="true">
        <Readings />
      </ParentWrapperView>
    ),
    title: "Readings",
  },
  {
    path: "/infusions",
    element: (
      <ParentWrapperView title="Infusions" sidebar="true">
        <Infusions />
      </ParentWrapperView>
    ),
    title: "Infusions",
  },
  {
    path: "/anesthesispath",
    element: (
      <ParentWrapperView title="Anesthesispath" sidebar="true">
        <Anesthesispath />
      </ParentWrapperView>
    ),
    title: "Anesthesispath",
  },
  {
    path: "/timers",
    element: (
      <ParentWrapperView title="Timers" sidebar="false">
        <Timers />
      </ParentWrapperView>
    ),
    title: "Timers",
  },
];

export { pagesData };
