import { baseHttp } from "./baseHttp";

const startSurgery = async (surgeryUUID: string, patient: string) => {
  return baseHttp
    .put(`/surgery-management/start/${surgeryUUID}`, { patient })
    .then((response: any) => response.data)
    .catch((error: any) => {
      // Anche in caso di errori hl7 considero valida la risposta al monitor
      if (error.response.status === 424) {
        return error.response.data;
      } else {
        throw error;
      }
    });
};

const endSurgery = async (surgeryUUID: string, patient: string) => {
  return baseHttp
    .put(`/surgery-management/close/${surgeryUUID}`, { patient })
    .then((response: any) => response.data)
    .catch((error: any) => {
      // Anche in caso di errori hl7 considero valida la risposta al monitor
      if (error.response.status === 424) {
        return error.response.data;
      } else {
        throw error;
      }
    });
};

const suspendSurgery = async (surgeryUUID: string, payload: { suspensionReason: string, patient: string }) => {
    return baseHttp
        .put(`/surgery-management/suspend/${surgeryUUID}`, payload)
        .then((response: any) => response.data)
        .catch((error: any) => {
            // Anche in caso di errori hl7 considero valida la risposta al monitor
            if (error.response.status === 424) {
                return error.response.data;
            } else {
                throw error;
            }
        });
};

const WrapperService = {
    startSurgery,
    endSurgery,
    suspendSurgery
}

export default WrapperService;