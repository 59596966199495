import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Table,
  Alert,
} from "antd";
import DatePicker from "../../components/DatePicker/DatePicker";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { CrudAction } from "../../enums/crudAction.enum";
import styles from "../../index.style";
import TimePicker from "../../components/TimePicker/TimePicker";
import { sharedInternalInfoState } from "../../recoil/atoms/sharedInternalAtom";
import ImplantablesService from "../../services/implantables.service";
import { Implantable } from "../../types/implantable.types";
import { userInfoState } from "../../recoil/atoms/userInfoAtom";
import { useTranslation } from "react-i18next";
import { changeShowLoading } from "../../components/Spinner/Spinner";

function Implantables() {
  const { t, i18n } = useTranslation();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [tableData, setTableData] = useState<any[]>([]);
  const [sharedInfo] = useRecoilState(sharedInternalInfoState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [implantableList, setImplantableList] = useState<Implantable[]>([]);
  const [paginationLoadList, setPaginationLoadList] = useState(50);
  const [implantableType, setImplantableType] = useState();
  const [modifyType, setModifyType] = useState(false);

  const [mode, setMode] = useState<CrudAction>(CrudAction.ADD);
  const [pagination, setPagination] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [form] = Form.useForm();
  const [matrixArticles, setMatrixArticles] = useState<{
    implantables: string;
  }>({ implantables: "" });

  const labelColSpan = 16;
  const wrapperColSpan = 8;

  const columns = [
    {
      title: " ",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any, rowIndex: number) => (
        <div>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            style={{ backgroundColor: styles.blueColor, padding: "7px" }}
            className="customButtonNoFloat"
            onClick={() => edit(record, rowIndex)}
          />
        </div>
      ),
    },
    {
      title: `${t("IMPLANTABLE")}`,
      dataIndex: "implantable",
      key: "implantables",
      render: (text: any, record: any) => (
        <div>
          <div>
            {record.implantableInfo.code} - {record.implantableInfo.description}{" "}
            ({record.implantableInfo.measureUnit})
          </div>
        </div>
      ),
    },
    {
      title: `${t("LOT")}`,
      dataIndex: "lot",
      key: "lot",
    },
    {
      title: `${t("QUANTITY")}`,
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: `${t("EXPIRATION")}`,
      dataIndex: "expirationDate",
      key: "expirationDate",
      render: (date: string) =>
        date
          ? DateTime.fromISO(date, {
              zone: "Europe/Rome", //TODO: Da scodare
            }).toLocaleString(DateTime.DATE_SHORT)
          : null,
    },
    {
      title: " ",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any, rowIndex: number) => (
        <div>
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            style={{ backgroundColor: styles.red, padding: "7px" }}
            className="customButtonNoFloat"
            onClick={() => deleteImplantable(rowIndex)}
          />
        </div>
      ),
    },
  ];

  const loadData = () => {
    const payload = {
      uuid: sharedInfo.surgery,
      tabName: "implantables-tab",
    };

    changeShowLoading(
      ImplantablesService.loadData(payload)
        .then((res) => {
          setTableData(res.implantable);
        })
        .catch((err) => {
          console.error(err);
        })
    );
  };

  const loadImplantablesList = (filter: string) => {
    const payload = [
      {
        field: "article_type.code",
        operator: "equal",
        value: matrixArticles.implantables,
      },
      {
        field: "t.validityEnd",
        operator: "isValid",
      },
    ];

    const queryparams = {
      page: 1,
      take: filter === "" ? paginationLoadList : 10000000000000,
      searchOn: "code,description",
      searchFor: filter,
    };

    if (matrixArticles.implantables) {
      changeShowLoading(
        ImplantablesService.getImplantables(payload, queryparams)
          .then((res) => {
            setImplantableList(res.list);
          })
          .catch((err) => {
            console.error(t("error"), err);
          })
      );
    }
  };

  const loadMatrixValue = () => {
    let matrix = userInfo.matrixPar["system.articles"];
    try {
      let info = JSON.parse(matrix);

      info = {
        implantables: info.implantables,
      };

      setMatrixArticles(info);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    loadMatrixValue();
  }, []);

  React.useEffect(() => {
    loadData();
  }, [matrixArticles]);

  const addImplantable = () => {
    setIsModalOpen(true);
    setMode(CrudAction.ADD);
  };

  const handleOk = () => {
    if (
      userInfo.capabilities["CAN_EDIT_ALL_TAB"] ||
      userInfo.capabilities["CAN_EDIT_IMPLANTABLES_TAB"] ||
      true
    ) {
      const data = form.getFieldsValue();
      const implantables = tableData.map((item) => {
        return {
          lot: item.lot,
          expirationDate: item.expirationDate,
          quantity: item.quantity || 1,
          implantable: item.implantableInfo.uuid,
        };
      });

      if (mode === CrudAction.ADD) {
        implantables.push(data);
      } else if (mode === CrudAction.UPDATE) {
        const currentIndex =
          (pagination.page - 1) * pagination.pageSize + selectedRowIndex;
        implantables[currentIndex] = data;
      }

      updateImplantables(implantables);
    } else {
      <Alert
        message={t("PERMISSION_NEGATE")}
        type="warning"
        showIcon
        closable
      />;
      setIsModalOpen(false);
      setModifyType(false);
    }
  };

  const handleCancel = () => {
    resetForm();
    setIsModalOpen(false);
    setModifyType(false);
    setImplantableType(undefined);
  };

  const onDrugTypeChange = (value: any) => {
    setImplantableType(value);
  };

  const edit = (record: any, rowIndex: number) => {
    setMode(CrudAction.UPDATE);
    setModifyType(true);
    setIsModalOpen(true);
    setSelectedRowIndex(rowIndex);

    form.setFieldsValue({
      implantable: record.implantableInfo.uuid,
      lot: record.lot,
      expirationDate: DateTime.fromISO(record.expirationDate, {
        zone: "Europe/Rome", //TODO: Da scodare
      }),
      quantity: record.quantity,
    });
  };

  const onScroll = (value: any) => {
    const target = value.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPaginationLoadList(paginationLoadList + 50);
    }
  };

  React.useEffect(() => {
    loadImplantablesList("");
  }, [paginationLoadList]);

  const onSearchValue = (value: any) => {
    loadImplantablesList(value);
  };

  const deleteImplantable = (rowIndex: number) => {
    if (
      userInfo.capabilities["CAN_EDIT_ALL_TAB"] ||
      userInfo.capabilities["CAN_EDIT_IMPLANTABLES_TAB"]
    ) {
      let implantables = tableData.map((item) => {
        return {
          lot: item.lot,
          expirationDate: item.expirationDate,
          quantity: item.quantity,
          implantable: item.implantableInfo.uuid,
        };
      });

      const currentIndex =
        (pagination.page - 1) * pagination.pageSize + rowIndex;
      implantables.splice(currentIndex, 1);
      updateImplantables(implantables);
    } else {
      <Alert
        message={t("PERMISSION_NEGATE")}
        type="warning"
        showIcon
        closable
      />;
    }
  };

  const updateImplantables = (implantables: Implantable[]) => {
    changeShowLoading(
      ImplantablesService.update(sharedInfo.surgery, {
        tabName: "implantables-tab",
        content: implantables,
      })
        .then(() => {
          setIsModalOpen(false);
          setModifyType(false);
          loadData();
        })
        .catch((err) => {
          console.error(err);
        })
    );
  };

  const resetForm = () => {
    form.resetFields();
  };

  React.useEffect(() => {
    if (isModalOpen) loadImplantablesList("");
    if (!isModalOpen) resetForm();
  }, [isModalOpen]);

  return (
    <>
      <Button
        type="primary"
        className="customButton mb-4"
        style={{ backgroundColor: styles.orange }}
        onClick={addImplantable}
      >
        <PlusOutlined />
      </Button>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        okButtonProps={{
          disabled: implantableType || modifyType ? false : true,
        }}
      >
        <div className="form-container">
          <Form 
            name="normal_login" 
            className="login-form" 
            form={form} 
            layout="horizontal" 
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 20,
            }}
          >
            <Form.Item
              name="implantable"
              className="form-container-item"
              label={t("TYPOLOGY")}
              rules={[
                {
                  required: true,
                  message: `${t("REQUIRED_FIELD")}`,
                },
              ]}
            >
              <Select
                showSearch
                className="w-full mt-4"
                placeholder={t("TYPOLOGY")}
                optionFilterProp="children"
                fieldNames={{ label: "description", value: "uuid" }}
                onChange={onDrugTypeChange}
                onPopupScroll={onScroll}
                onSearch={onSearchValue}
                filterOption={(input, option: any) =>
                  option.description
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                options={implantableList}
              />
            </Form.Item>

            <Form.Item
              name="lot"
              className="form-container-item"
              label={t("LOT")}
            >
              <Input placeholder={`${t("BASIC_USAGE")}`} />
            </Form.Item>

            <Form.Item
              name="expirationDate"
              className="form-container-item"
              label={t("EXPIRATION_DATE")}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>

            <Form.Item
              name="quantity"
              label={t("QUANTITY")}
              className="form-container-item"
              initialValue={1}
            >
              <InputNumber size="large" type="number" stringMode min="1" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Table
        dataSource={tableData}
        columns={columns}
        onChange={(pagination, filters, sorter, extra) => {
          setPagination({
            page: pagination.current || 1,
            pageSize: pagination.pageSize || 10,
          });
        }}
      />
    </>
  );
}

export default Implantables;
