import {
  Button,
  Layout,
  Menu,
  MenuProps,
  Modal,
  Space,
  Form,
  Input,
} from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Footer, Header } from "antd/es/layout/layout";
import "./Wrapper.css";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styles from "../../index.style";
import { getUserInfoState } from "../../recoil/atoms/userInfoAtom";
import { Clock } from "../../types/wrapper.types";
import {
  CalculatorOutlined,
  ClockCircleOutlined,
  FieldTimeOutlined,
  LineChartOutlined,
  MedicineBoxOutlined,
  PieChartOutlined,
  ToolOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import CookieService from "../../services/cookie.service";
import { getPatientInfoState } from "../../recoil/atoms/patientInfoAtom";
import { sharedInternalInfoState } from "../../recoil/atoms/sharedInternalAtom";
import { SurgeryManagementStatus } from "../../enums/surgeryManagementStatus.enum";
import WrapperService from "../../services/wrapper.service";
import { useTranslation } from "react-i18next";
import Spinner from "../Spinner/Spinner";
import { changeShowLoading } from "../../components/Spinner/Spinner";
import { ChecklistTabName } from "../../enums/checklist.enum";
import { DateTime } from "luxon";

type MenuItem = Required<MenuProps>["items"][number];

function ParentWrapperView(payload: {
  children: any;
  title: string;
  sidebar: string;
}) {
  let navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [suspensionForm] = Form.useForm();

  const wrapperUserInfo = useRecoilValue(getUserInfoState);
  const matrixPar = wrapperUserInfo?.matrixPar;
  const userFirstLastname = wrapperUserInfo?.userInfo
    ? `${wrapperUserInfo?.userInfo?.firstName || ""} ${
        wrapperUserInfo?.userInfo?.lastName || ""
      }`
    : "";

  const patientInfo = useRecoilValue(getPatientInfoState);
  const [sharedInfo, setSharedInfo] = useRecoilState(sharedInternalInfoState);
  const [isSuspensionModalOpen, setIsSuspensionModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [colorSidebar, setColorSidebar] = useState('white');

  const [date, setDate] = useState({} as Clock);
  function refreshClock() {
    setDate({ currenDate: new Date() });
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    setSidebarColor();

    return function cleanup() {
      clearInterval(timerId);
    };
    // eslint-disable-next-line
  }, []);

  function switchNavigation(label: React.ReactNode): void {
    //TODO MARIO: RIMUOVERE
    const sharedInfo = JSON.parse(
      localStorage.getItem("current.surgery") || "{}"
    );

    switch (label) {
      case `${t('EQUIPE')}`:
        navigate("/equipe", {
          state: { uuid: sharedInfo.surgery },
        });
        break;
      case `${t('MATERIAL')}`:
        navigate("/materials", {
          state: { uuid: sharedInfo.surgery },
        });
        break;
      case `${t('IMPLANTABLE')}`:
        navigate("/implantables", {
          state: { uuid: sharedInfo.surgery },
        });
        break;
      case `${t('FINDINGS')}`:
        navigate("/readings", {
          state: { uuid: sharedInfo.surgery },
        });
        break;
      case `${t('INFUSIONS')}`:
        navigate("/infusions", {
          state: { uuid: sharedInfo.surgery },
        });
        break;
      case `${t('OPERATING_PATH')}`:
        navigate("/checklist/PO", {
          state: { uuid: sharedInfo.surgery, patient: patientInfo.uuid, phaseName: ChecklistTabName.PO },
        });
        break;
      case `${t('NURSING_RECORD')}`:
        navigate("/checklist/CI", {
          state: { uuid: sharedInfo.surgery, patient: patientInfo.uuid, phaseName: ChecklistTabName.CI },
        });
        break;
      case `${t('ANESTHESIOLOGY_RECORD')}`:
        navigate("/checklist/CA", {
          state: { uuid: sharedInfo.surgery, patient: patientInfo.uuid, phaseName: ChecklistTabName.CA },
        });
        break;
      case `${t('ANESTHESIOLOGY_PATHWAY')}`:
        navigate("/anesthesispath", {
          state: { uuid: sharedInfo.surgery },
        });
        break;
    }
  }

  function getColor(color: string | undefined): string {
    switch (color) {
      case "yellow":
        return styles.yellow;
      case "pink":
        return styles.pink;
      case "default":
        return styles.badgeLightBlueBg;
    }

    return styles.badgeLightBlueBg;
  }

  function checkDisabled(name: React.ReactNode) {
    // TODO MARIO: RIMUOVERE
    const sharedInfo = JSON.parse(
      localStorage.getItem("current.checklists") || "{}"
    );
    switch (name) {
      case `${t('OPERATING_PATH')}`:
        return sharedInfo?.checklists?.po;
      case `${t('NURSING_RECORD')}`:
        return sharedInfo?.checklists?.ci;
      case `${t('ANESTHESIOLOGY_RECORD')}`:
        return sharedInfo?.checklists?.ca;
      case `${t('ANESTHESIOLOGY_PATHWAY')}`:
        return sharedInfo?.checklists?.pa;
    }
    return true;
  }

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    color?: string,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      children,
      label: (
        <Button
          icon={icon}
          className="w-full font-semibold text-base rounded-full"
          type="primary"
          style={{
            backgroundColor: getColor(color),
            color: styles.blueColor,
          }}
          onClick={() => switchNavigation(label)}
          disabled={!checkDisabled(label)}
        >
          {label}
        </Button>
      ),
      title: <span>{label}</span>,
    } as MenuItem;
  }

  const setSidebarColor = () => {
    let classMed = wrapperUserInfo.userInfo.operatorType;
    const operatorTypes = matrixPar && matrixPar['system.operatorType'] ? JSON.parse(matrixPar['system.operatorType']) : {};

    if(!Object.keys(operatorTypes).length) {
      console.error("Missing operatorTypes in matrixPar. I will use default color");
    }

    switch (classMed){
      case operatorTypes.medic && operatorTypes.medic.toUpperCase():
        setColorSidebar('#00CD66');
        break;
      case operatorTypes.nurse && operatorTypes.nurse.toUpperCase():
        setColorSidebar('#FFFF00');
        break;
      case operatorTypes.anesthesis && operatorTypes.anesthesis.toUpperCase():
        setColorSidebar('#FFB5C5');
        break;
      default:
        setColorSidebar('white');
    }
  }

  const items: MenuItem[] = [
    getItem(`${t('EQUIPE')}`, "1", <PieChartOutlined className={collapsed ? 'ant-btn-icon align-center-icon' : undefined}/>, "default"),
    getItem(`${t('MATERIAL')}`, "2", <ToolOutlined className={collapsed ? 'ant-btn-icon align-center-icon' : undefined}/>, "default"),
    getItem(`${t('IMPLANTABLE')}`, "3", <CalculatorOutlined className={collapsed ? 'ant-btn-icon align-center-icon' : undefined}/>, "default"),
    getItem(`${t('FINDINGS')}`, "4", <LineChartOutlined className={collapsed ? 'ant-btn-icon align-center-icon' : undefined}/>, "yellow"),
    getItem(`${t('INFUSIONS')}`, "5", <MedicineBoxOutlined className={collapsed ? 'ant-btn-icon align-center-icon' : undefined}/>, "pink"),
    getItem(`${t('CHECKLIST')}`, "sub1", <UnorderedListOutlined className={collapsed ? 'ant-btn-icon align-center-icon' : undefined}/>, "default", [
      getItem(`${t('OPERATING_PATH')}`, "6", <FieldTimeOutlined />, "default"),
      getItem(
        `${t('NURSING_RECORD')}`,
        "7",
        <FieldTimeOutlined />,
        "default"
      ),
      getItem(
        `${t('ANESTHESIOLOGY_RECORD')}`,
        "8",
        <FieldTimeOutlined />,
        "default"
      ),
    ]),
    getItem(`${t('ANESTHESIOLOGY_PATHWAY')}`, "9", <ClockCircleOutlined className={collapsed ? 'ant-btn-icon align-center-icon' : undefined} />, "pink"),
  ];

  function statusButtonLabel(): string {
    const currentStatus = sharedInfo.status;
    switch (currentStatus) {
      case SurgeryManagementStatus.ONGOING:
        return `${t('FINISH')}`;
      case SurgeryManagementStatus.CONFIRMED:
        return `${t('START')}`;
      case SurgeryManagementStatus.SUSPENDED:
        return `${t('SUSPENDED')}`;
    }
    return `${t('TERMINATED')}`;
  }

  function _handleStartEndSurgery() {
    const currentStatus = sharedInfo.status;
    switch (currentStatus) {
      case SurgeryManagementStatus.CONFIRMED:
        changeShowLoading(WrapperService.startSurgery(sharedInfo.surgery, patientInfo.uuid)
          .then(() => {
            setSharedInfo({
              ...sharedInfo,
              status: SurgeryManagementStatus.ONGOING,
            });
          })
          .catch((error) => {
            console.log(error);
          }));
        break;
      case SurgeryManagementStatus.ONGOING:
        changeShowLoading(WrapperService.endSurgery(sharedInfo.surgery, patientInfo.uuid)
          .then(() => {
            setSharedInfo({
              ...sharedInfo,
              status: SurgeryManagementStatus.CLOSED,
            });
          })
          .catch((error) => {
            console.log(error);
          }));
        break;
    }
  }
  function _handleSuspendSurgery() {
    const formItems = suspensionForm.getFieldsValue();
    changeShowLoading(WrapperService.suspendSurgery(sharedInfo.surgery, {
      suspensionReason: formItems.reason,
      patient: patientInfo.uuid,
    })
      .then(() => {
        setSharedInfo({
          ...sharedInfo,
          status: SurgeryManagementStatus.SUSPENDED,
        });
      })
      .catch((error) => {
        console.log(error);
      }));
  }

  const showModal = () => {
    setIsSuspensionModalOpen(true);
  };

  const handleOk = () => {
    _handleSuspendSurgery();
    setIsSuspensionModalOpen(false);
  };

  const handleCancel = () => {
    setIsSuspensionModalOpen(false);
  };

  const PatientInfo = () => {
    return (
      <>
        <div
          className={
            "flex flex-col pl-2 items-center justify-center text-xl gap-2 pt-4 mb-4 "
          }
        >
          <div className="flex flex-row gap-4">
            <UserOutlined
              style={{ color: styles.blueColor, fontSize: "50px" }}
            />
            <div className="flex flex-col text-base font-semibold">
              <span style={{ color: styles.blueColor }}>
                {patientInfo.firstName} {patientInfo.lastName}
              </span>
              <span style={{ color: styles.lightBlueBgColor }}>
                {patientInfo.tin}
              </span>
            </div>
          </div>
        </div>
        <div className={"flex flex-col items-center justify-center text-base "}>
          <div className="flex flex-row gap-4">
            <span style={{ color: styles.blueColor }}>{t('DATE_OF_BIRTH')}: </span>
            <span
              style={{
                color: styles.lightBlueBgColor,
                backgroundColor: styles.badgeLightBlueBg,
              }}
              className="rounded-full px-2 text-sm font-semibold"
            >
              {DateTime.fromISO(patientInfo.birthDay).toFormat('dd-MM-yyyy')}
            </span>
          </div>
          <div className="flex flex-row gap-4">
            <span style={{ color: styles.blueColor }}>{t('INTERVENTION_DATE')}: </span>
            <span
              style={{
                color: styles.lightBlueBgColor,
                backgroundColor: styles.badgeLightBlueBg,
              }}
              className="rounded-full px-2 text-sm font-semibold"
            >
              {DateTime.fromISO(patientInfo.surgeryDate).toFormat('dd-MM-yyyy')}
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Spinner></Spinner>
      <Layout
        className="layout"
        style={{
          backgroundColor: styles.greyColor,
          minHeight: "100vh",
        }}
      >
        {payload?.sidebar === "true" && (
          <Sider
            breakpoint="lg"
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            theme="light"
            style={{ backgroundColor: styles.greyColor, overflow: "hidden" }}
            width="300"
          >
            <div style={{ height: "160px", position: "sticky",  top: "0px", zIndex: '10', backgroundColor: 'rgb(217, 231, 235)'}}>
              {collapsed ? null : <PatientInfo />}
            </div>

            <Menu
              theme="light"
              defaultSelectedKeys={["6"]}
              mode="inline"
              items={items}
              expandIcon={<div></div>}
            />
          </Sider>
        )}
        <Layout>
          <Header
            className="text-white"
            style={{
              backgroundColor: styles.blueColor,
            }}
          >
            <div className="grid grid-cols-12 h-full text-2xl ">
              <div className="col-span-8 font-semibold text-white ml-4 pt-4">
                {CookieService.getSurgeryRoomDescription()}
              </div>
              <div
                className="col-span-4 bg-blue-400 text-center items-center justify-between pt-4"
                style={{ backgroundColor: styles.lightBlueBgColor }}
              >
                <div className="font-semibold text-white flex align-items-center"></div>
                {date?.currenDate?.toLocaleString()}
              </div>
            </div>
          </Header>
          <Content className="content">
            {payload?.sidebar === "true" && (
              <div
                className="flex justify-end text-center py-4"
                style={{ marginRight: "10px" }}
              >
                <Modal
                  title={t('CONFIGURATION_PANEL')}
                  open={isSuspensionModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  maskClosable={false}
                  closable={false}
                >
                  <Form
                    name="suspension_modal_form"
                    className="suspension_modal_form"
                    form={suspensionForm}
                    layout="vertical"
                  >
                    <Form.Item
                      name="reason"
                      label={t('REASON')}
                      className="form-container-item"
                      required={true}
                      rules={[
                        {
                          required: true,
                          message: `${t('REQUIRED_FIELD')}`,
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </Form>
                </Modal>
                <Space wrap className="mr-4">
                  <Button
                    className="customButton mr-8"
                    type="primary"
                    style={{
                      backgroundColor: styles.lightBlueBgColor,
                    }}
                    onClick={() => navigate("/surgeries")}
                  >
                    {t('INTERVENTION_LIST')}
                  </Button>
                  {sharedInfo.status === SurgeryManagementStatus.ONGOING ? (
                    <Button
                      className="customButton mr-8"
                      type="primary"
                      style={{
                        backgroundColor: styles.red,
                      }}
                      onClick={() => showModal()}
                    >
                      {t('SUSPEND')}
                    </Button>
                  ) : null}
                  <Button
                    className="customButton"
                    type="primary"
                    style={{
                      backgroundColor: styles.orange,
                    }}
                    onClick={() => _handleStartEndSurgery()}
                  >
                    {statusButtonLabel()}
                  </Button>
                </Space>
              </div>
            )}
            <Content
              className={`Content--Layout overflow-y-auto ${
                payload?.sidebar === "true" ? "internal" : ""
              }`}
            >
              {payload.children}
            </Content>
          </Content>
          <Footer className="footer bg-white pt-2 pb-2"  style={{backgroundColor: colorSidebar, zIndex: '10'}}>
            <div className="grid grid-cols-12 h-full text-2xl ">
              <div
                className="col-span-4 font-semibold text-xl ml-4 pt-4"
                style={{ color: styles.blueColor }}
              >
                <UserOutlined /> {userFirstLastname}
              </div>
              <div className="col-span-8 flex justify-end text-center pt-4 mr-4 pb-4">
                <Space wrap>
                  {/* <Button
                    className="customButton"
                    type="primary"
                    style={{
                      backgroundColor: styles.lightBlueBgColor,
                    }}
                  >
                    Cambio Operatore
                  </Button> */}
                  <Button
                    className="customButton"
                    type="primary"
                    style={{
                      backgroundColor: styles.blueColor,
                    }}
                    onClick={() => navigate("/timers")}
                  >
                    {t('TIMER_LIST')}
                  </Button> 
                  <Button
                    className="customButton"
                    type="primary"
                    style={{
                      backgroundColor: styles.orange,
                    }}
                    onClick={() => navigate("/login")}
                  >
                    {t('EXIT')}
                  </Button>
                </Space>
              </div>
            </div>
          </Footer>
        </Layout>
      </Layout>
    </>
  );
}

export default ParentWrapperView;
