import { atom } from "recoil";

export const currentActiveTimerState = atom({
    key: 'currentActiveTimers',
    default: [{
        description: '',
        expirationDate: '',
        type: '',
        index: 0,
        isRunning: false,
        dateStarted: '',
        value: 0,
        oldValue: 0,
    }]
});