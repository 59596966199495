import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Modal,
  Table,
  Form,
  Select,
  Radio,
  InputNumber,
  Alert,
} from "antd";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import TimePicker from "../../components/TimePicker/TimePicker";
import { InfusionActionType } from "../../enums/infusion.enum";
import { sharedInternalInfoState } from "../../recoil/atoms/sharedInternalAtom";
import InfusionService from "../../services/infusions.service";
import { AbnormalFilter } from "../../types/common.types";
import { Infusion } from "../../types/infusions.type";
import styles from "../../index.style";
import "./Infusions.css";
import { customTableTitle } from "../../utils/tables.utils";
import { userInfoState } from "../../recoil/atoms/userInfoAtom";
import { useTranslation } from "react-i18next";
import { changeShowLoading } from "../../components/Spinner/Spinner";

function Infusions() {
  const { t, i18n } = useTranslation();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [tableData, setTableData] = useState<Infusion[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infusionTypeList, setInfusionTypeList] = useState([]);
  const [drugsList, setDrugsList] = useState([]);
  const [measureUnitList, setMeasureUnitList] = useState([]);
  const [sharedInfo, setSharedInfo] = useRecoilState(sharedInternalInfoState);
  const [paginationLoadType, setPaginationLoadType] = useState(50);
  const [paginationLoadDrug, setPaginationLoadDrug] = useState(50);

  const [timePickerValue, setTimePickerValue] = useState<string | null>(null);
  const [deletingRow, setDeletingRow] = useState(false);
  const [mode, setMode] = useState(InfusionActionType.NONE);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [drugTypeValue, setDrugTypeValue] = useState("");
  const [pagination, setPagination] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });
  const [currentRow, setCurrentRow] = useState<{
    rowIndex: number;
    event: any;
    record: any;
  }>();

  const [form] = Form.useForm();
  const labelColSpan = 16;
  const wrapperColSpan = 8;
  const columns = [
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any, rowIndex: number) => (
        <Button
          type="primary"
          icon={<SearchOutlined />}
          style={{ backgroundColor: styles.blueColor, padding: "7px" }}
          className="customButtonNoFloat"
          onClick={() => editSelectedInfusion(record, rowIndex)}
        />
      ),
    },
    {
      title: customTableTitle(`${t("DRUG")}`, ""),
      dataIndex: "drug",
      key: "drug",
      render: (text: any, record: any) => (
        <div>
          <span>
            {record.infusionInfoType?.code} -{" "}
            {record.infusionInfoType?.description}
          </span>
          <br />
          <span>
            {record.infusionInfo?.code} - {record.infusionInfo?.description}
          </span>
        </div>
      ),
    },
    {
      title: customTableTitle(`${t("ASSURED")}`, ""),
      dataIndex: "checked",
      key: "checked",
      render: (text: any, record: any) => (
        <div>
          {record.verified ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
        </div>
      ),
    },
    {
      title: customTableTitle(`${t("DOSAGE")}`, ""),
      dataIndex: "dosage",
      key: "dosage",
      render: (text: any, record: any) => (
        <div>
          <p>
            {record?.dosage}{" "}
            {record?.measureInfo?.description
              ? record?.measureInfo?.description
              : ""}
          </p>
        </div>
      ),
    },
    {
      title: customTableTitle(`${t("DILUITION")}`, ""),
      dataIndex: "diluition",
      key: "diluition",
    },
    {
      title: customTableTitle(`${t("SPEED")}`, ""),
      dataIndex: "speed",
      key: "speed",
    },
    {
      title: customTableTitle(`${t("START")}`, ""),
      dataIndex: "startDate",
      key: "start",
      render: (date: string) =>
        date
          ? DateTime.fromISO(date, {
              zone: "Europe/Rome", //TODO: Da scodare
            }).toLocaleString(DateTime.DATETIME_MED)
          : null,
    },
    {
      title: customTableTitle(`${t("END")}`, ""),
      dataIndex: "endDate",
      key: "end",
      render: (date: string) =>
        date
          ? DateTime.fromISO(date, {
              zone: "Europe/Rome", //TODO: Da scodare
            }).toLocaleString(DateTime.DATETIME_MED)
          : null,
    },
    {
      title: " ",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any, rowIndex: number) => (
        <div>
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            style={{ backgroundColor: styles.red, padding: "7px" }}
            className="customButtonNoFloat"
            onClick={() => prepareToDeleteInfusion(record)}
          />
        </div>
      ),
    },
  ];

  const prepareToDeleteInfusion = (record: Infusion) => {
    if (
      userInfo.capabilities["CAN_EDIT_ALL_TAB"] ||
      userInfo.capabilities["CAN_EDIT_INFUSIONS_TAB"]
    ) {
      setMode(InfusionActionType.DELETE);
      const data = tableData.filter((item) => item !== record);
      setTableData(data);
      setDeletingRow(true);
    } else {
      <Alert
        message={t("PERMISSION_NEGATE")}
        type="warning"
        showIcon
        closable
      />;
    }
  };

  const loadData = () => {
    const payload = {
      uuid: sharedInfo.surgery,
      tabName: "infusions-tab",
    };
    changeShowLoading(
      InfusionService.loadData(payload)
        .then((response) => {
          setTableData(response.infusion);
        })
        .catch((error) => {
          console.error(error);
        })
    );
  };

  const addInfusion = () => {
    setIsModalOpen(true);
    setMode(InfusionActionType.ADD);
  };

  const prepareUploadPayload = () => {
    console.log("prepareUploadPayload");
    console.log(tableData);
    return (tableData || []).map((item) => {
      return {
        verified: item.verified,
        dosage: item.dosage,
        measureUnit: item.measureInfo?.uuid,
        speed: item.speed,
        drug: item.infusionInfo.uuid,
        diluition: item.diluition,
        startDate: DateTime.fromISO(item.startDate, {
          zone: "Europe/Rome", //TODO: Da scodare
        }).toFormat("yyyy-MM-dd HH:mm:ss"),
        endDate:
          item.endDate &&
          DateTime.fromISO(item.endDate, {
            zone: "Europe/Rome", //TODO: Da scodare
          }).toFormat("yyyy-MM-dd HH:mm:ss"),
      };
    });
  };

  const handleOk = () => {
    if (
      userInfo.capabilities["CAN_EDIT_ALL_TAB"] ||
      userInfo.capabilities["CAN_EDIT_INFUSIONS_TAB"]
    ) {
      const data = form.getFieldsValue();
      form
        .validateFields()
        .then(() => {
          updateInfusionList(data);
        })
        .catch((error) => {
          console.info("Some fields are missing", error);
        });
    } else {
      <Alert
        message={t("PERMISSION_NEGATE")}
        type="warning"
        showIcon
        closable
      />;
      setIsModalOpen(false);
    }
  };

  const updateInfusionList = (data: any) => {
    let payload = prepareUploadPayload();
    console.log("PAYLOAD: ", payload, "DATA: ", data);

    if (mode === InfusionActionType.ADD) {
      // format data
      data.startDate = DateTime.fromISO(data.startDate, {
        zone: "Europe/Rome", //TODO: Da scodare
      }).toFormat("yyyy-MM-dd HH:mm:ss");
      data.endDate =
        data.endDate &&
        DateTime.fromISO(data.endDate, {
          zone: "Europe/Rome", //TODO: Da scodare
        }).toFormat("yyyy-MM-dd HH:mm:ss");
      payload.push(data);
    } else if (mode === InfusionActionType.UPDATE) {
      // format data
      data.startDate = DateTime.fromISO(data.startDate, {
        zone: "Europe/Rome", //TODO: Da scodare
      }).toFormat("yyyy-MM-dd HH:mm:ss");
      data.endDate =
        data.endDate &&
        DateTime.fromISO(data.endDate, {
          zone: "Europe/Rome", //TODO: Da scodare
        }).toFormat("yyyy-MM-dd HH:mm:ss");
      payload[selectedRowIndex] = data;
    }

    changeShowLoading(
      InfusionService.update(sharedInfo.surgery, {
        tabName: "infusions-tab",
        content: payload,
      })
        .then(() => {
          form.resetFields();
          setIsModalOpen(false);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setMode(InfusionActionType.NONE);
          setSelectedRowIndex(-1);
        })
    );
  };

  const loadDrugTypeList = (filter: string) => {
    const payload: AbnormalFilter[] = [
      {
        field: "t.validityEnd",
        operator: "isValid",
      },
    ];

    const queryParams = {
      page: 1,
      take: filter === "" ? paginationLoadType : 100000000000,
      searchOn: "code,description",
      searchFor: filter,
    };

    changeShowLoading(
      InfusionService.getDrugsTypes(payload, queryParams)
        .then((response) => {
          setInfusionTypeList(response.list);
        })
        .catch((error) => {
          console.error(error);
        })
    );
  };

  const loadDrugs = (filter: string) => {
    const queryParams = {
      page: 1,
      take: filter !== "" || drugTypeValue ? 100000000000 : paginationLoadDrug,
      searchOn: "code,description",
      searchFor: filter,
    };

    const payload: AbnormalFilter[] = [
      {
        field: "t.validityEnd",
        operator: "isValid",
      },
    ];

    if (drugTypeValue) {
      payload.push({
        field: "t.drug_type",
        operator: "equal",
        value: drugTypeValue,
      });
    }

    changeShowLoading(
      InfusionService.getDrugs(payload, queryParams)
        .then((response) => {
          setDrugsList(response.list);
        })
        .catch((error) => {
          console.error(error);
        })
    );
  };

  const loadMeasureUnit = () => {
    const payload: AbnormalFilter[] = [
      {
        field: "t.validityEnd",
        operator: "isValid",
      },
    ];

    const queryParams = {
      page: 1,
      take: 50,
      searchOn: "code,description",
      searchFor: "",
    };

    changeShowLoading(
      InfusionService.getMeasureUnit(payload, queryParams)
        .then((response) => {
          setMeasureUnitList(response.list);
        })
        .catch((error) => {
          console.error(error);
        })
    );
  };

  const handleCancel = () => {
    setDrugTypeValue("");
    form.resetFields();
    setIsModalOpen(false);
    setMode(InfusionActionType.NONE);
    setSelectedRowIndex(-1);
  };

  const onDrugTypeChange = (value: any) => {
    setDrugTypeValue(value);
  };

  const editSelectedInfusion = (record: Infusion | any, rowIndex: number) => {
    const currentIndex = (pagination.page - 1) * pagination.pageSize + rowIndex;

    setMode(InfusionActionType.UPDATE);
    setSelectedRowIndex(currentIndex);
    setIsModalOpen(true);
    setDrugTypeValue(record.infusionInfoType.uuid);

    form.setFieldsValue({
      verified: record.verified,
      dosage: record.dosage,
      speed: record.speed,
      startDate: DateTime.fromISO(record.startDate, {
        zone: "Europe/Rome", //TODO: Da scodare
      }).toFormat("yyyy-MM-dd HH:mm:ss"),
      endDate:
        record.endDate &&
        DateTime.fromISO(record.endDate, {
          zone: "Europe/Rome", //TODO: Da scodare
        }).toFormat("yyyy-MM-dd HH:mm:ss"),
      measureUnit: record.measureInfo?.uuid,
      drugType: record.infusionInfoType?.uuid,
      drug: record.infusionInfo?.uuid,
      diluition: record.diluition,
    });
  };

  const onRowClick = (e: any, record: Infusion | any, rowIndex: number) => {
    const payload = {
      event: e,
      record: record,
      rowIndex: rowIndex,
    };

    setCurrentRow(payload);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  React.useEffect(() => {
    if (!isModalOpen) loadData();
  }, [isModalOpen]);

  React.useEffect(() => {
    switch (mode) {
      case InfusionActionType.NONE:
        break;
      case InfusionActionType.ADD:
      case InfusionActionType.UPDATE:
        loadDrugTypeList("");
        loadDrugs("");
        loadMeasureUnit();
        break;
      case InfusionActionType.DELETE:
        updateInfusionList(null);
        break;
      default:
        break;
    }
  }, [mode]);

  React.useEffect(() => {
    loadDrugs("");
  }, [drugTypeValue]);

  const onScroll = (value: any, type: string) => {
    const target = value.target;
    if (type === "farmaco") {
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        setPaginationLoadDrug(paginationLoadDrug + 50);
      }
    } else {
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        setPaginationLoadType(paginationLoadType + 50);
      }
    }
  };

  React.useEffect(() => {
    loadDrugs("");
  }, [paginationLoadDrug]);

  React.useEffect(() => {
    loadDrugTypeList("");
  }, [paginationLoadType]);

  const onSearchValue = (value: any, type: string) => {
    if (type === "farmaco") {
      loadDrugs(value);
    } else {
      loadDrugTypeList(value);
    }
  };

  const setTime = (e: any) => {
    setTimePickerValue(e?.setZone("Europe/Rome").toISO());
  };

  React.useEffect(() => {
    console.log(timePickerValue);
  }, [timePickerValue]);

  const setTypologyValue = (value: any) => {
    let drugType = "";

    drugsList.map((item: any) => {
      if (item.uuid === value) {
        drugType = item.drug_type.uuid;
      }
    });

    const payload: AbnormalFilter[] = [
      {
        field: "t.validityEnd",
        operator: "isValid",
      },
      {
        field: "t.uuid",
        operator: "equal",
        value: drugType,
      },
    ];

    const queryParams = {
      page: 1,
      take: 1000000,
      searchOn: "code,description",
      searchFor: "",
    };

    changeShowLoading(
      InfusionService.getDrugsTypes(payload, queryParams)
        .then((response) => {
          form.setFieldsValue({
            drugType: response.list[0].description,
          });
          setInfusionTypeList(response.list);
        })
        .catch((error) => {
          console.error(error);
        })
    );
  };

  React.useEffect(() => {
    if (!deletingRow) return;
    updateInfusionList(null);
  }, [deletingRow]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
      >
        <div className="form-container">
          <Form 
            name="normal_login" 
            className="login-form" 
            form={form}
            layout="horizontal" 
            labelCol={{
              span: 7,
            }}
            wrapperCol={{
              span: 20,
            }}
          >
            <Form.Item
              name="drugType"
              className="form-container-item"
              label={t("TYPOLOGY")}
              rules={[
                {
                  required: true,
                  message: `${t("REQUIRED_FIELD")}`,
                },
              ]}
            >
              <Select
                showSearch
                className="w-full "
                placeholder={t("TYPOLOGY")}
                optionFilterProp="children"
                fieldNames={{ label: "description", value: "uuid" }}
                onChange={onDrugTypeChange}
                onPopupScroll={(value) => onScroll(value, "tipo")}
                onSearch={(value) => onSearchValue(value, "tipo")}
                filterOption={(input, option: any) =>
                  option.description
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                options={infusionTypeList}
              />
            </Form.Item>
            <Form.Item
              name="drug"
              className="form-container-item"
              label={t("DRUG")}
              rules={[
                {
                  required: true,
                  message: `${t("REQUIRED_FIELD")}`,
                },
              ]}
            >
              <Select
                showSearch
                className="w-full "
                placeholder={t("DRUG")}
                optionFilterProp="children"
                fieldNames={{ label: "description", value: "uuid" }}
                onChange={(value) => setTypologyValue(value)}
                onPopupScroll={(value) => onScroll(value, "farmaco")}
                onSearch={(value) => onSearchValue(value, "farmaco")}
                filterOption={(input, option: any) =>
                  option.description
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                options={drugsList}
              />
            </Form.Item>

            <Form.Item
              name="verified"
              label={t("ASSURED")}
              className="form-container-item"
            >
              <Radio.Group
                className="flex justify-end form-container-radio flex-row-reverse"
                optionType="button"
                buttonStyle="solid"
                size="large"
              >
                <Radio value={true}> {t("YES")} </Radio>
                <Radio value={false}> {t("NO")} </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="dosage"
              label={t("DOSAGE")}
              className="form-container-item"
            >
              <InputNumber
                size="large"
                type="number"
                stringMode
                min={0}
                placeholder={`${t("DOSAGE")}`}
              />
            </Form.Item>

            <Form.Item
              name="measureUnit"
              className="form-container-item"
              label={t("MEASURE_UNIT")}
            >
              <Select
                showSearch
                className="w-full "
                placeholder={t("MEASURE_UNIT")}
                optionFilterProp="children"
                fieldNames={{ label: "description", value: "uuid" }}
                filterOption={(input, option: any) =>
                  option.description
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                options={measureUnitList}
              />
            </Form.Item>

            <Form.Item
              name="diluition"
              label={t("DILUITION")}
              className="form-container-item"
            >
              <InputNumber
                size="large"
                type="number"
                min={0}
                placeholder={`${t("DILUITION")}`}
              />
            </Form.Item>

            <Form.Item
              name="speed"
              label={t("SPEED")}
              className="form-container-item"
            >
              <InputNumber
                size="large"
                type="number"
                min={0}
                placeholder={`${t("SPEED")}`}
              />
            </Form.Item>

            <Form.Item
              name="startDate"
              className="form-container-item"
              label={t("START_DATE")}
              rules={[
                {
                  required: true,
                  message: `${t("REQUIRED_FIELD")}`,
                },
              ]}
            >
              <TimePicker format="HH:mm:ss" changeOnBlur />
            </Form.Item>

            <Form.Item
              name="endDate"
              className="form-container-item"
              label={t("END_DATE")}
            >
              <TimePicker format="HH:mm:ss" changeOnBlur />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Button
        type="primary"
        className="customButton mb-4"
        style={{ backgroundColor: styles.orange }}
        onClick={addInfusion}
      >
        <PlusOutlined />
      </Button>
      <Table
        dataSource={tableData}
        columns={columns}
        rowClassName="infusion-row"
        onChange={(pagination, filters, sorter, extra) => {
          console.log("params", pagination);
          setPagination({
            page: pagination.current || 1,
            pageSize: pagination.pageSize || 10,
          });
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              if (rowIndex !== undefined) {
                // editSelectedInfusion(record, rowIndex);
                onRowClick(e, record, rowIndex);
              }
            },
          };
        }}
      />
    </>
  );
}

export default Infusions;
