import React from "react";
import "./RedirectHandler.css";

// import db
import { db } from "../../persistance/db";
import { useNavigate } from "react-router-dom";
import CookieService from "../../services/cookie.service";
import { DBKeys } from "../../enums/db.enum";
import { CustomErrorsCode } from "../../enums/customErrors.enum";


function RedirectHandler() {
  let navigate = useNavigate();

 
  React.useEffect(() => {
    // check if exists id 1 in db.settings
    navigationHandler();
  }, []);

  
  const navigationHandler = async () => {
    try {
      const settings = await db.settings.get(DBKeys.SYSCONFIG);
      if (settings && settings.urlApi && settings.divisionUUID && settings.surgeryRoomUUID) {
        CookieService.setApiUrl(settings.urlApi);
        CookieService.setSurgeryRoomUUID(settings.surgeryRoomUUID);
        CookieService.setSurgeryRoomDescription(settings.surgeryRoomDescription);
        CookieService.setSurgeryRoomBlocksUUID(settings.surgeryRoomBlocksUUID);
        CookieService.setDivisionUUID(settings.divisionUUID);
        CookieService.setDivisionDescription(settings.divisionDescription);
        // react navigate to /login
        navigate("/login");
      }else{
        // react navigate to /settings
        navigate("/settings");
      }
    } catch (error) {
      // react navigate to /settings
      navigate("/maintenance/"+ CustomErrorsCode.INDEXDB_UPGRADE_NEEDED);
    }
  };

  return (
    <>
    </>
  );
}

export default RedirectHandler;
