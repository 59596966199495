import { Spin } from "antd";
import "./Spinner.css";

let load = false;
let hidingPromises: any[] = [];

export const changeShowLoading = async (showSpinner: Promise<void>) => {
    load = true;

    if (showSpinner) {
      hidingPromises.push(showSpinner);
      await showSpinner.finally(() => {
        hidingPromises.pop();
        if (hidingPromises.length === 0) {
            load = false;
          }
      });
      return showSpinner;
    }
};

const Spinner = () => {
    return(
        <>
        <div className="overlay" style={{display: (load ? 'block' : 'none')}}>
            <div className="boxContainer">
                <Spin tip="Loading" size="large" style={{marginTop: '4rem'}}>
                    <div className="content" />
                </Spin>
            </div>
        </div>
        </>
    );
};

export default Spinner;