import ar_EG from "antd/es/locale/ar_EG";
import az_AZ from "antd/es/locale/az_AZ";
import bg_BG from "antd/es/locale/bg_BG";
import bn_BD from "antd/es/locale/bn_BD";
import by_BY from "antd/es/locale/by_BY";
import ca_ES from "antd/es/locale/ca_ES";
import cs_CZ from "antd/es/locale/cs_CZ";
import da_DK from "antd/es/locale/da_DK";
import de_DE from "antd/es/locale/de_DE";
import el_GR from "antd/es/locale/el_GR";
import en_GB from "antd/es/locale/en_GB";
import en_US from "antd/es/locale/en_US";
import es_ES from "antd/es/locale/es_ES";
import eu_ES from "antd/es/locale/eu_ES";
import et_EE from "antd/es/locale/et_EE";
import fa_IR from "antd/es/locale/fa_IR";
import fi_FI from "antd/es/locale/fi_FI";
import fr_BE from "antd/es/locale/fr_BE";
import fr_CA from "antd/es/locale/fr_CA";
import fr_FR from "antd/es/locale/fr_FR";
import ga_IE from "antd/es/locale/ga_IE";
import gl_ES from "antd/es/locale/gl_ES";
import he_IL from "antd/es/locale/he_IL";
import hi_IN from "antd/es/locale/hi_IN";
import hr_HR from "antd/es/locale/hr_HR";
import hu_HU from "antd/es/locale/hu_HU";
import hy_AM from "antd/es/locale/hy_AM";
import id_ID from "antd/es/locale/id_ID";
import it_IT from "antd/es/locale/it_IT";
import is_IS from "antd/es/locale/is_IS";
import ja_JP from "antd/es/locale/ja_JP";
import ka_GE from "antd/es/locale/ka_GE";
import kmr_IQ from "antd/es/locale/kmr_IQ";
import kn_IN from "antd/es/locale/kn_IN";
import kk_KZ from "antd/es/locale/kk_KZ";
import km_KH from "antd/es/locale/km_KH";
import ko_KR from "antd/es/locale/ko_KR";
import lt_LT from "antd/es/locale/lt_LT";
import lv_LV from "antd/es/locale/lv_LV";
import mk_MK from "antd/es/locale/mk_MK";
import ml_IN from "antd/es/locale/ml_IN";
import mn_MN from "antd/es/locale/mn_MN";
import ms_MY from "antd/es/locale/ms_MY";
import nb_NO from "antd/es/locale/nb_NO";
import ne_NP from "antd/es/locale/ne_NP";
import nl_BE from "antd/es/locale/nl_BE";
import nl_NL from "antd/es/locale/nl_NL";
import pl_PL from "antd/es/locale/pl_PL";
import pt_BR from "antd/es/locale/pt_BR";
import pt_PT from "antd/es/locale/pt_PT";
import ro_RO from "antd/es/locale/ro_RO";
import ru_RU from "antd/es/locale/ru_RU";
import si_LK from "antd/es/locale/si_LK";
import sk_SK from "antd/es/locale/sk_SK";
import sr_RS from "antd/es/locale/sr_RS";
import sl_SI from "antd/es/locale/sl_SI";
import sv_SE from "antd/es/locale/sv_SE";
import ta_IN from "antd/es/locale/ta_IN";
import th_TH from "antd/es/locale/th_TH";
import tr_TR from "antd/es/locale/tr_TR";
import tk_TK from "antd/es/locale/tk_TK";
import ur_PK from "antd/es/locale/ur_PK";
import uk_UA from "antd/es/locale/uk_UA";
import vi_VN from "antd/es/locale/vi_VN";
import zh_CN from "antd/es/locale/zh_CN";
import zh_HK from "antd/es/locale/zh_HK";
import zh_TW from "antd/es/locale/zh_TW";
import my_MM from "antd/es/locale/my_MM";

export const language = window.navigator.language;

export const locales: { [key: string]: any } = {
  ar_EG: ar_EG,
  az_AZ: az_AZ,
  bg_BG: bg_BG,
  bn_BD: bn_BD,
  by_BY: by_BY,
  ca_ES: ca_ES,
  cs_CZ: cs_CZ,
  da_DK: da_DK,
  de_DE: de_DE,
  el_GR: el_GR,
  en_GB: en_GB,
  en_US: en_US,
  es_ES: es_ES,
  eu_ES: eu_ES,
  et_EE: et_EE,
  fa_IR: fa_IR,
  fi_FI: fi_FI,
  fr_BE: fr_BE,
  fr_CA: fr_CA,
  fr_FR: fr_FR,
  ga_IE: ga_IE,
  gl_ES: gl_ES,
  he_IL: he_IL,
  hi_IN: hi_IN,
  hr_HR: hr_HR,
  hu_HU: hu_HU,
  hy_AM: hy_AM,
  id_ID: id_ID,
  it_IT: it_IT,
  is_IS: is_IS,
  ja_JP: ja_JP,
  ka_GE: ka_GE,
  kmr_IQ: kmr_IQ,
  kn_IN: kn_IN,
  kk_KZ: kk_KZ,
  km_KH: km_KH,
  ko_KR: ko_KR,
  lt_LT: lt_LT,
  lv_LV: lv_LV,
  mk_MK: mk_MK,
  ml_IN: ml_IN,
  mn_MN: mn_MN,
  ms_MY: ms_MY,
  nb_NO: nb_NO,
  ne_NP: ne_NP,
  nl_BE: nl_BE,
  nl_NL: nl_NL,
  pl_PL: pl_PL,
  pt_BR: pt_BR,
  pt_PT: pt_PT,
  ro_RO: ro_RO,
  ru_RU: ru_RU,
  si_LK: si_LK,
  sk_SK: sk_SK,
  sr_RS: sr_RS,
  sl_SI: sl_SI,
  sv_SE: sv_SE,
  ta_IN: ta_IN,
  th_TH: th_TH,
  tr_TR: tr_TR,
  tk_TK: tk_TK,
  ur_PK: ur_PK,
  uk_UA: uk_UA,
  vi_VN: vi_VN,
  zh_CN: zh_CN,
  zh_HK: zh_HK,
  zh_TW: zh_TW,
  my_MM: my_MM,
};
