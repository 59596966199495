import { EquipeUserPayload } from "../types/equipe.type";
import { UserRoleResponse } from "../types/login.types";
import { baseHttp } from "./baseHttp";

const retrieveUserInRole = async (surgeryRoomBlockUUID: string | null): Promise<UserRoleResponse> => {
  let path = surgeryRoomBlockUUID ? `/operator/all/type/${surgeryRoomBlockUUID}` : `/operator/all/type`;
  return baseHttp.get(path).then((response: any) => response.data);
};

const loadData = async (payload: { uuid: string; tabName: string }) => {
  return baseHttp
    .post(`/surgery-management-revision/last/revision/monitor`, payload)
    .then((response: any) => response.data);
};

const update = async (
  surgeryUUID: string,
  payload: {
    tabName: string;
    content: Array<EquipeUserPayload>;
  }
) => {
  return baseHttp
    .post(`/surgery-management/monitor/update/${surgeryUUID}`, payload)
    .then((response: any) => response.data);
};


const EquipeService = {
  retrieveUserInRole,
  update,
  loadData
}

export default EquipeService;
