import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReadingsService from "../../services/readings.service";
import { Button, Modal, Table, Form, Input, Select, Checkbox, Alert } from "antd";
import {
  FixedDataType,
  AllColumnsType,
  ReadingsCheckpoint,
  CustomColumnClicked,
  SavePayload,
} from "../../types/readings.types";
import styles from "../../index.style";
import { PlusOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import "./Readings.css";
import { useRecoilState } from "recoil";
import { sharedInternalInfoState } from "../../recoil/atoms/sharedInternalAtom";
import { userInfoState } from "../../recoil/atoms/userInfoAtom";
import { useTranslation } from "react-i18next";
import { changeShowLoading } from "../../components/Spinner/Spinner";

function Readings() {
  const location = useLocation();
  // checkpoints for modal
  const [checkpoints, setCheckpoints] = useState([] as ReadingsCheckpoint[]);
  // all table columns
  const [allColumns, setAllColumns] = useState([] as AllColumnsType[]);
  // all table data
  const [fixedData, setFixedData] = useState([] as FixedDataType[]);
  // modal handler
  const [isReadingsModalOpen, setIsReadingsModalOpen] = useState(false);
  // column clicked to open the modal
  const [clickedColumn, setClickedColumn] = useState({} as CustomColumnClicked);
  // eslint-disable-next-line
  const [sharedInfo, _] = useRecoilState(sharedInternalInfoState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();

  const labelColSpan = 14;
  const wrapperColSpan = 10;

  function loadReadings() {
    changeShowLoading(ReadingsService.load({ uuid: location.state.uuid }).then((resp) => {
      let firstColumns: AllColumnsType[] = [
        {
          title: "Timer",
          dataIndex: "timer",
          fixed: true,
          width: 200,
        },
      ];

      let fixedData: FixedDataType[] = [];
      const allQuestions = resp?.readings?.map((point: any, idx: string) => {
        fixedData.push({
          key: `timer-${idx}`,
          timer: point?.checkpoint_uuid?.description || "",
          code: point?.checkpoint_uuid?.code,
        });
        return point?.checkpoint_uuid;
      });
      // check if there are answers on DB
      setCheckpoints(allQuestions);

      if (resp.anesthesis.length > 0) {
        // per ogni data
        resp.anesthesis.forEach(
          (ane: { date: string; readings: { [key: string]: string } }) => {
            // creo una colonna vuota
            const now = DateTime.fromISO(ane.date, {
              zone: "Europe/Rome",
            }).toFormat("HH:mm:ss");
            const newCol: AllColumnsType = {
              title: now,
              dataIndex: `custom-column-${now}`,
              className: `custom-column-${now}`,
            };
            // per ogni rilevazione
            Object.keys(ane.readings).forEach((key: string) => {
              // se è vuota skippo
              const element = ane.readings[key];
              if (["", undefined].includes(element)) {
                return;
              }
              // cerco la riga nei dati attuali e aggiungo il valore
              const oldDataIndex = fixedData.findIndex((el) => el.code === key);
              fixedData[oldDataIndex][`custom-column-${now}`] = element;
            });
            firstColumns.push(newCol);
          }
        );
      }
      setAllColumns(firstColumns);

      setFixedData(fixedData);
    }));
  }

  useEffect(() => {
    loadReadings();
    // eslint-disable-next-line
  }, []);

  function addGridColumn(): void {
    if(userInfo.capabilities["CAN_EDIT_ALL_TAB"] || userInfo.capabilities["CAN_EDIT_ANESTHESIS_TAB"]){
      const now =
        `${DateTime.local().setZone("Europe/Rome").toFormat("HH:mm:ss")}` || "";
      const allTitles = allColumns.map((el) => el.title);
      if (allTitles.includes(now)) {
        return;
      }
      const newCol: AllColumnsType = {
        title: now,
        dataIndex: `custom-column-${now}`,
        className: `custom-column-${now}`,
      };
      setAllColumns((current) => [...current, ...[newCol]]);
      _handleUpdate(newCol);
      // setFixedData((current) => [...current, ...[]]);
    }else{
      <Alert message={t('PERMISSION_NEGATE')} type="warning" showIcon closable />
    }
  }

  function removeGridColumn(): void {
    if(userInfo.capabilities["CAN_EDIT_ALL_TAB"] || userInfo.capabilities["CAN_EDIT_ANESTHESIS_TAB"]){
      const columnIndex = clickedColumn.index;
      // -1 not found, 0 is header column
      if (columnIndex > 0) {
        const removedElement = allColumns.splice(columnIndex, 1);
        // riassegno altrimenti non triggera il cambiamento per lo splice
        setAllColumns(() => [...allColumns]);
        fixedData.forEach((data) => {
          delete data[removedElement[0].className || ""];
        });
      }
      setIsReadingsModalOpen(false);

      _handleUpdate();
    }else{
      <Alert message={t('PERMISSION_NEGATE')} type="warning" showIcon closable />
    }
  }

  // type EventTarget
  const showModal = (event: any) => {
    const customColumnRegex = event.className.match(/custom-column-.*\s/);
    if (!customColumnRegex?.length) {
      // click on first column
      return;
    }
    setIsReadingsModalOpen(true);
    /** start clicked column */

    const customColumnRegexMatch = customColumnRegex[0];
    const customColumn = customColumnRegexMatch.replace(/\s+\S*$/, "");
    const currentColumnIndex = allColumns.findIndex(
      (x) => x.className === customColumn
    );
    const currentColumn: AllColumnsType = allColumns[currentColumnIndex];
    /** end clicked column */
    /** start restore form */
    const formToRestore: { [key: string]: string } = {};
    fixedData.forEach((data) => {
      formToRestore[data.code] = data[customColumn];
    });
    /** end restore form */

    setClickedColumn({
      title: currentColumn.title,
      dataIndex: currentColumn.dataIndex,
      className: currentColumn.className,
      index: currentColumnIndex,
    });

    form.setFieldsValue({ ...formToRestore }); // restore form data
  };

  const handleOk = () => {
    const formItems = form.getFieldsValue();
    // console.log("ANSWERS: ", formItems);
    // console.log("QUESTIONS: ", checkpoints);
    // console.log("ALL COLUMNS: ", allColumns);
    // console.log("COLUMN: ", clickedColumn);
    // console.log("DATA: ", fixedData);
    const dataIndex = clickedColumn.dataIndex;
    // fixedData sono le row
    fixedData.forEach((cp) => {
      // per ogni row, in timer c'è la desc del checkpoint
      // cerco il codice del checkpoint
      const foundCheckpointCode = cp?.code || "";
      const answer = formItems[foundCheckpointCode] || "";
      cp[dataIndex] = answer;
      cp["title"] = clickedColumn.title;
    });
    setFixedData([...fixedData]);
    form.resetFields();
    setIsReadingsModalOpen(false);
    _handleUpdate();
  };

  const handleCancel = () => {
    setIsReadingsModalOpen(false);
  };

  const handleDelete = () => {
    removeGridColumn();
  };

  function _handleUpdate(newCol?: AllColumnsType): void {
    let currentAllColumns = allColumns;
    /**
     * quando si aggiunge una nuova colonna si arriva qui e
     * la colonna non è stata ancora aggiunta
     */
    if (newCol) {
      const allTitles = allColumns.map((el) => el.title);
      if (!allTitles.includes(newCol.title)) {
        currentAllColumns = [...currentAllColumns, ...[newCol]];
      }
    }
    // console.log("QUESTIONS: ", checkpoints);
    // console.log("CURRENT ALL COLUMNS: ", currentAllColumns);
    // console.log("COLUMN: ", clickedColumn);
    // console.log("DATA: ", fixedData);
    const dataColumnsTitle = currentAllColumns.filter(
      (el) => el.title !== "Timer"
    );
    const payload: SavePayload[] = [];
    dataColumnsTitle.forEach((el) => {
      const objToPush: SavePayload = {
        date: DateTime.fromFormat(el.title, "HH:mm:ss", {
          zone: "Europe/Rome",
        }).toISO(),
        readings: {},
      };
      fixedData.forEach((els) => {
        objToPush.readings[els.code] = els[`custom-column-${el.title}`];
      });
      payload.push(objToPush);
    });

    changeShowLoading(ReadingsService.update(sharedInfo.surgery, {
      tabName: "anesthesis-tab",
      content: payload,
    }));
  }

  return (
    <>
      <Modal
        title={clickedColumn.title}
        centered
        open={isReadingsModalOpen}
        width={800}
        closable={false}
        maskClosable={false}
        destroyOnClose
        footer={[
          <Button key="back" onClick={handleCancel} className="float-left">
            {t('CANCEL')}
          </Button>,
          <Button
            type="primary"
            className="mr-10"
            danger
            onClick={handleDelete}
          >
            {t('DELETE')}
          </Button>,

          <Button key="submit" type="primary" onClick={handleOk}>
            {t('OK')}
          </Button>,
        ]}
      >
        <div className="form-container">
          <Form name="normal_login" className="login-form" form={form}>
            {checkpoints?.length
              ? checkpoints.map((cp: any, index: number) => {
                  return (
                    <>
                      {cp.type === "text" ? (
                        <Form.Item
                          name={cp.code}
                          label={cp.question}
                          labelCol={{
                            span: labelColSpan,
                          }}
                          wrapperCol={{
                            span: wrapperColSpan,
                          }}
                        >
                          <Input.TextArea />
                        </Form.Item>
                      ) : null}
                      {cp.type === "dropdown" ? (
                        <Form.Item
                          name={cp.code}
                          label={cp.question}
                          labelCol={{
                            span: labelColSpan,
                          }}
                          wrapperCol={{
                            span: wrapperColSpan,
                          }}
                        >
                          <Select
                            className="w-full"
                            optionFilterProp="children"
                            fieldNames={{
                              label: "description",
                              value: "key",
                            }}
                            filterOption={(input, option: any) =>
                              option.description.toLowerCase().indexOf(input.toLowerCase()) >= 0                 
                            }
                            options={cp.answers}
                          />
                        </Form.Item>
                      ) : null}
                      {cp.type === "boolean" ? (
                        <Form.Item
                          name={cp.code}
                          label={cp.question}
                          labelCol={{
                            span: labelColSpan,
                          }}
                          wrapperCol={{
                            span: wrapperColSpan,
                          }}
                        >
                          <Checkbox></Checkbox>
                        </Form.Item>
                      ) : null}
                    </>
                  );
                })
              : null}
          </Form>
        </div>
      </Modal>
      <Button
        type="primary"
        className="customButton mb-4"
        style={{ backgroundColor: styles.orange }}
        onClick={() => addGridColumn()}
      >
        <PlusOutlined />
      </Button>
      <Table
        style={{ whiteSpace: "nowrap" }}
        columns={allColumns}
        dataSource={fixedData}
        pagination={false}
        scroll={{ x: 1000 }}
        bordered
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              showModal(event.target);
              //   console.log("click ", typeof event, event);
            }, // click row
            // onDoubleClick: (event) => {}, // double click row
            // onContextMenu: (event) => {}, // right button click row
            // onMouseEnter: (event) => {}, // mouse enter row
            // onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        onHeaderRow={(columns, index) => {
          return {
            onClick: () => {
              console.log("header ", columns, index);
            }, // click header row
          };
        }}
      />
    </>
  );
}

export default Readings;
