export enum ChecklistTab {
  PO = "path-tab",
  CA = "anesthesis-folder-tab",
  CI = "nursing-folder-tab",
  PA = "anesthesis-path-tab",
}

export enum ChecklistTabName {
  PO = "PO",
  CA = "CA",
  CI = "CI",
  PA = "PA",
}