export const Cookies = {
    TOKEN: 'sisos-access-token',
    APIURL: 'sisos-api-base-url',
    SURGERYROOMUUID: 'sisos-surgery-room-uuid',
    SURGERYROOMDESCRIPTION: 'sisos-surgery-room-description',
    SURGERYROOMBLOCKSUUID: 'sisos-surgery-room-blocks-uuid',
    DIVISIONUUID: 'sisos-division-uuid',
    DIVISIONDESCRIPTION: 'sisos-division-description'
}


/** COOKIES METHODS START 
 * https://www.w3schools.com/js/js_cookies.asp
*/

const getCookie = (cname: string) => {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

const setCookie = (cname: string, cvalue: string, exdays: number) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

/** COOKIES METHODS END */

/** @param cnameToken = 'sisos-access-token' */

export const deleteCookie = (cname: string) => {
    setCookie(cname, '', -1);
};

export const getToken = (cname: string) => {
    return getCookie(cname);
};

export const setToken = (cname: string, cvalue: string, exdays: number) => {
    return setCookie(cname, cvalue, exdays);
};

export const getRefreshToken = (cname: string) => {
    return getCookie(cname);
};

export const setRefreshToken = (cname: string, cvalue: string, exdays: number) => {
    return setCookie(cname, cvalue, exdays);
};

export const deleteToken = (cname: string) => {
    return deleteCookie(cname);
};

export const deleteRefreshToken = (cname: string) => {
    return deleteCookie(cname);
};

export const getApiUrl = () => {
    return getCookie(Cookies.APIURL);
};

export const setApiUrl = (cvalue: string) => {
    return setCookie(Cookies.APIURL, cvalue, 2147483647);
};

export const getSurgeryRoomUUID = () => {
    return getCookie(Cookies.SURGERYROOMUUID);
};

export const setSurgeryRoomUUID = (cvalue: string) => {
    return setCookie(Cookies.SURGERYROOMUUID, cvalue, 2147483647);
};

export const getSurgeryRoomDescription = () => {
    return getCookie(Cookies.SURGERYROOMDESCRIPTION);
};

export const setSurgeryRoomDescription = (cvalue: string) => {
    return setCookie(Cookies.SURGERYROOMDESCRIPTION, cvalue, 2147483647);
};

export const getSurgeryRoomBlocksUUID = () => {
    return getCookie(Cookies.SURGERYROOMBLOCKSUUID);
};

export const setSurgeryRoomBlocksUUID = (cvalue: string) => {
    return setCookie(Cookies.SURGERYROOMBLOCKSUUID, cvalue, 2147483647);
};

export const setDivisionUUID = (cvalue: string) => {
    return setCookie(Cookies.DIVISIONUUID, cvalue, 2147483647);
};

export const getDivisionUUID = () => {
    return getCookie(Cookies.DIVISIONUUID);
}

export const setDivisionDescription = (cvalue: string) => {
    return setCookie(Cookies.DIVISIONDESCRIPTION, cvalue, 2147483647);
};

export const getDivisionDescription = () => {
    return getCookie(Cookies.DIVISIONDESCRIPTION);
}

const CookieService = {
    getToken,
    setToken,
    deleteToken,
    getApiUrl,
    setApiUrl,
    getSurgeryRoomUUID,
    setSurgeryRoomUUID,
    getSurgeryRoomDescription,
    setSurgeryRoomDescription,
    getSurgeryRoomBlocksUUID,
    setSurgeryRoomBlocksUUID,
    setDivisionUUID,
    getDivisionUUID,
    setDivisionDescription,
    getDivisionDescription
}

export default CookieService;
