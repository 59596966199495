export enum DBKeys {
  // KEYS FOR SETTINGS
  SYSCONFIG = "sysconfig",
  // KEYS FOR OTHER TABLES
}


export enum DBTables {
  // TABLES FOR SETTINGS
  SETTINGS = "settings",
}
