// import "./Anesthesispath.css";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Form, Input, Radio, Select, Alert } from "antd";
import { useRecoilState } from "recoil";
import { DateTime } from "luxon";
import { ChecklistTab } from "../../enums/checklist.enum";
import TimePicker from "../../components/TimePicker/TimePicker";
import { sharedInternalInfoState } from "../../recoil/atoms/sharedInternalAtom";
import AnesthesispathService from "../../services/anesthesispath.service";
import {Checkpoint } from "../../types/checklist.types";
import { userInfoState } from "../../recoil/atoms/userInfoAtom";
import { useTranslation } from "react-i18next";
import { changeShowLoading } from "../../components/Spinner/Spinner";

function Anesthesispath() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { type } = useParams();
  const [form] = Form.useForm();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const [anesthesisPathResponse, setAnesthesisPathResponse] = useState<any>({});
  const [checkpoints, setCheckpoints] = useState(Array<Checkpoint>());

  const [sharedInfo, setSharedInfo] = useRecoilState(sharedInternalInfoState);

  const labelColSpan = 16;
  const wrapperColSpan = 8;

  /**********USE EFFECTS**********/
  React.useEffect(() => {
    loadData();
  }, [type]);

  React.useEffect(() => {
    if (checkpoints.length > 0) {
      restoreForm();
    }
  }, [checkpoints]);


  /**********HOOKS**********/
  const loadData = async () => {
    const payload = {
      uuid: location.state.uuid,
      tabName: ChecklistTab.PA,
    };

    let result = {uuid: '', anesthesisPath: []};
    await changeShowLoading(AnesthesispathService.loadData(payload).then((el) => {
      result = {...el};
    }).catch(
      (err) => {
        console.log(t('ERROR'), err);
      }
    ));

    if(result && result.uuid){
      setAnesthesisPathResponse(result);
      setCheckpoints(result.anesthesisPath);
    }
  };


  const restoreForm = () => {
    let formData = {} as any;
    for (const cp of checkpoints) {
      if (cp.checkpointInfo.type === "timer") {
        cp.submittedAnswers = DateTime.fromISO(cp.submittedAnswers, {
          zone: "Europe/Rome", //TODO: Da scodare
        }).toString();
      }
      if (cp.submittedAnswers) {
        formData[cp.checkpointInfo.uuid] = cp.submittedAnswers;
      }
    }
    form.setFieldsValue({ ...formData }); // restore form data
  };

  const onValuesChange = () => {
    if(userInfo.capabilities["CAN_EDIT_ALL_TAB"] || userInfo.capabilities["CAN_EDIT_ANESTHESIS_PATH_TAB"]){
      // get form data and send 
      const formData = form.getFieldsValue();

      const checkpointsToSubmit = checkpoints
        .map((cp: Checkpoint) => {
          return {
            checkpoint: cp.checkpointInfo.uuid,
            type: cp.checkpointInfo.type,
            question: cp.question,
            submittedAnswers: formData[cp.checkpointInfo.uuid] || null,
          };
        });

      let checklistUUID = checkpoints[0].checklist;

      const payload = {
        tabName: ChecklistTab.PA.toString(),
        content: {
          checklist: checklistUUID,
          checkpoints: checkpointsToSubmit,
        }
      };

      changeShowLoading(AnesthesispathService.update(sharedInfo.surgery, payload).catch((err) => {
        console.log(t("ERROR"), err);
      }));
    }else{
      <Alert message={t('PERMISSION_NEGATE')} type="warning" showIcon closable />
    }
  };

  return (
    <>
      <div className="form-container">
        {  (checkpoints || []).map(
            (cp: Checkpoint, index: number) => {
              return (
                <Form
                  name="normal_login"
                  className="login-form"
                  form={form}
                  onBlur={onValuesChange}
                >
                  {cp.checkpointInfo.type === "boolean" ? (
                    <Form.Item
                      name={cp.checkpointInfo.uuid}
                      label={cp.question}
                      className="form-container-item"
                      labelCol={{
                        span: labelColSpan,
                      }}
                      wrapperCol={{
                        span: wrapperColSpan,
                      }}
                      rules={[
                        {
                          required: cp.checkpointInfo.mandatory,
                          message: `${t('REQUIRED_FIELD')}`,
                        },
                      ]}
                    >
                      <Radio.Group
                        className="flex justify-end form-container-radio"
                        optionType="button"
                        buttonStyle="solid"
                        size="large"
                      >
                        <Radio value="true"> {t('YES')} </Radio>
                        <Radio value="false"> {t('NO')} </Radio>
                        <Radio value="nd"> {t('NOT_DEFINED')} </Radio>
                      </Radio.Group>
                    </Form.Item>
                  ) : null}

                  {cp.checkpointInfo.type === "text" ? (
                    <Form.Item
                      name={cp.checkpointInfo.uuid}
                      label={cp.question}
                      className="form-container-item"
                      labelCol={{
                        span: labelColSpan,
                      }}
                      wrapperCol={{
                        span: wrapperColSpan,
                      }}
                      rules={[
                        {
                          required: cp.checkpointInfo.mandatory,
                          message: `${t('REQUIRED_FIELD')}`,
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  ) : null}

                  {cp.checkpointInfo.type === "dropdown" ? (
                    <Form.Item
                      name={cp.checkpointInfo.uuid}
                      className="form-container-item"
                      label={cp.question}
                      labelCol={{
                        span: labelColSpan,
                      }}
                      wrapperCol={{
                        span: wrapperColSpan,
                      }}
                      rules={[
                        {
                          required: cp.checkpointInfo.mandatory,
                          message: `${t('REQUIRED_FIELD')}`,
                        },
                      ]}
                    >
                      <Select
                        className="w-full"
                        optionFilterProp="children"
                        fieldNames={{
                          label: "description",
                          value: "key",
                        }}
                        options={cp.checkpointInfo.answers}
                        filterOption={(input, option: any) =>
                          option.description.toLowerCase().indexOf(input.toLowerCase()) >= 0                 
                        }
                      />
                    </Form.Item>
                  ) : null}

                  {cp.checkpointInfo.type === "multiple" ? (
                    <Form.Item
                      name={cp.checkpointInfo.uuid}
                      className="form-container-item"
                      label={cp.question}
                      labelCol={{
                        span: labelColSpan,
                      }}
                      wrapperCol={{
                        span: wrapperColSpan,
                      }}
                      rules={[
                        {
                          required:cp.checkpointInfo.mandatory,
                          message: `${t('REQUIRED_FIELD')}`,
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        className="w-full"
                        optionFilterProp="children"
                        fieldNames={{
                          label: "description",
                          value: "key",
                        }}
                        options={cp.checkpointInfo.answers}
                        filterOption={(input, option: any) =>
                          option.description.toLowerCase().indexOf(input.toLowerCase()) >= 0                 
                        }
                      />
                    </Form.Item>
                  ) : null}

                  {cp.checkpointInfo.type === "timer" ? (
                    <Form.Item
                      name={cp.checkpointInfo.uuid}
                      className="form-container-item"
                      label={cp.question}
                      labelCol={{
                        span: labelColSpan,
                      }}
                      wrapperCol={{
                        span: wrapperColSpan,
                      }}
                      rules={[
                        {
                          required: cp.checkpointInfo.mandatory,
                          message: `${t('REQUIRED_FIELD')}`,
                        },
                      ]}
                    >
                      <TimePicker format="HH:mm:ss" changeOnBlur/>
                    </Form.Item>
                  ) : null}
                </Form>
              );
            }
          )}
      </div>
    </>
  );
}

export default Anesthesispath;
