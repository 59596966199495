import { SavePayload } from "../types/readings.types";
import { baseHttp } from "./baseHttp";

const load = async (payload: {
    uuid: string;
}) => {
    return baseHttp
        .post(`/surgery-management-revision/last/revision/monitor`, { 'uuid': payload.uuid, 'tabName': 'anesthesis-tab' })
        .then((response: any) => response.data);
};

const update = async (surgeryUUID: string, payload: {
    tabName: string;
    content: SavePayload[]
}
) => {
    return baseHttp
        .post(`/surgery-management/monitor/update/${surgeryUUID}`, payload)
        .then((response: any) => response.data);
};


const ReadingsService = {
    load,
    update
}

export default ReadingsService;