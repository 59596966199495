import { SurgeriesCalendarResponse } from "../types/calendar.types";
import { baseHttp } from "./baseHttp";

const retrieveSurgeriesCalendarInfo = async (payload: Object[]): Promise<SurgeriesCalendarResponse> => {
    return baseHttp.post(`/surgery-management/calendar/count/filter?q=1&page=1&take=10000`, payload).then((response: any) => response.data)
}

const CalendarService = {
    retrieveSurgeriesCalendarInfo,
}

export default CalendarService;