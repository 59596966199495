import React from "react";
import styles from "../index.style";

export function customTableTitle(title: string, customColor: string) {
  return (
    <p
      className="text-lg font-semibold"
      style={{ color: customColor !== "" ? customColor : styles.blueColor }}
    >
      {title}
    </p>
  );
}
