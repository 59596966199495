import "./Surgeries.css";
import React from "react";
import { DataType, SurgeryManagment } from "../../types/surgeries.types";
import SurgeryService from "../../services/surgery.service";
import { AbnormalFilter } from "../../types/common.types";
import { Button, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import styles from "../../index.style";
import { useLocation, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import CookieService from "../../services/cookie.service";
import { useRecoilState } from "recoil";
import { patientInfoState } from "../../recoil/atoms/patientInfoAtom";
import { sharedInternalInfoState } from "../../recoil/atoms/sharedInternalAtom";
import { customTableTitle } from "../../utils/tables.utils";
import { useTranslation } from "react-i18next";
import { changeShowLoading } from "../../components/Spinner/Spinner";

function Surgeries() {
  let navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line
  const [_, setPatientInfo] = useRecoilState(patientInfoState);
  // eslint-disable-next-line
  const [__, setSharedInfo] = useRecoilState(sharedInternalInfoState);
  const yScroll = true; // disable yscroll
  const { t, i18n } = useTranslation();

  const scroll: { x?: number | string; y?: number | string } = {};
  if (yScroll) {
    scroll.y = 300;
  }

  function navigateToChecklist(record: DataType) {
    changeShowLoading(SurgeryService.getChecklistExist(record.uuid).then((check) => {
      setPatientInfo({
        firstName: record.firstName,
        lastName: record.lastName,
        tin: record.tin,
        birthDay: record.birthDay,
        surgeryDate:
          location?.state?.date ||
          DateTime.now().startOf("day").toFormat("yyyy-MM-dd"),
        uuid: record.patientUuid,
      });
      setSharedInfo({
        surgery: record.uuid,
        checklists: check,
        status: record.status,
      });
      //TODO MARIO: RIMUOVERE
      localStorage.setItem(
        "current.surgery",
        JSON.stringify({ surgery: record.uuid })
      );
      localStorage.setItem(
        "current.checklists",
        JSON.stringify({ checklists: check })
      );
      localStorage.setItem(
        "current.status",
        JSON.stringify({ status: record.status })
      );
      navigate("/checklist/PO", {
        state: { uuid: record.uuid, type: "path-tab" },
      });
    }));
  }

  const headerRender = (): ColumnsType<DataType> => {
    return [
      {
        title: customTableTitle(`${t('START_INTERVENTION')}`, ""),
        dataIndex: "slotStart",
        key: "slotStart",
        width: "8%",
        render: (date: string) =>
          date
            ? DateTime.fromISO(date, {
                zone: "Europe/Rome",
              }).toLocaleString(DateTime.TIME_24_SIMPLE)
            : null,
      },
      {
        title: customTableTitle(`${t('PATIENT_TAX_CODE')}`, ""),
        dataIndex: "tin",
        key: "tin",
        width: "20%",
      },
      {
        title: customTableTitle(`${t('NAME_AND_SURNAME')}`, ""),
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <div>
            <span>{record.firstName}</span>
            <br />
            <span>{record.lastName}</span>
            <br />
          </div>
        ),
      },
      {
        title: customTableTitle(`${t('NUMBER_AND_REVISION')}`, styles.orange),
        dataIndex: "revision",
        key: "revision",
        render: (text, record) => (
          <div style={{ color: styles.orange }}>
            <span>{record.counterValue}</span>
            <br />
            <span>{record.revision}</span>
          </div>
        ),
      },
      {
        title: customTableTitle(`${t('SURGERY')}`, ""),
        dataIndex: "surgery",
        key: "surgery",
        render: (text, record) => (
          <div>
            <span>{record.surgery.code}</span>
            <br />
            <span>{record.surgery.description}</span>
            <br />
          </div>
        ),
      },
      {
        title: customTableTitle(`${t('GUARD_DEPARTMENT')}`, ""),
        dataIndex: "guardDept",
        key: "guardDept",
        width: "10%",
        render: (text, record) => (
          <div>
            <span>{record.wardDept.code}</span>
            <br />
            <span>{record.wardDept.description}</span>
            <br />
          </div>
        ),
      },
      //{
        //title: customTableTitle("Diagnosi", ""),
        //dataIndex: "diagnosis",
        //key: "diagnosis",
        //render: (text, record) => {
          //const allDiagnosis = record.diagnosis
            //.map((el) => el.description)
            //.join(" - ");
          //return (
            //<div>
              //<span className="custom-truncate">{allDiagnosis}</span>
            //</div>
          //);
        //},
      //},
      //{
        //title: customTableTitle("Operatori", ""),
        //dataIndex: "operators",
        //key: "operators",
        //render: (text, record) => {
          //const allOperators = record.operators
            //.map((el) => el.description)
            //.join(" - ");
          //return (
            //<div>
              //<span className="custom-truncate">{allOperators}</span>
            //</div>
          //);
        //},
      //},
      {
        title: customTableTitle(`${t('INTERVENTION_DATE')}`, ""),
        dataIndex: "surgeryDate",
        key: "surgeryDate",
        width: "10%",
        render: (date: string) =>
          date
            ? DateTime.fromISO(date, {
                zone: "Europe/Rome",
              }).toLocaleString(DateTime.DATE_SHORT)
            : null,
      },
      {
        title: customTableTitle(`${t('STATUS')}`, ""),
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <Button
            type="primary"
            size={"middle"}
            className="customButtonNoFloat mr-4"
            style={{ backgroundColor: styles.lightBlueBgColor }}
            onClick={() => navigateToChecklist(record)}
          >
            {t(record.status.toUpperCase())}
          </Button>
        ),
      },
    ];
  };

  const columns: ColumnsType<DataType> = headerRender();

  const [surgeries, setSurgeries] = React.useState([] as DataType[]);

  const loadSurgeries = async (data: string) => {
    //FIXME //TODO data.surgeryroom_uuid va preso da configurazione

    let currentDate: Array<string> = ["", ""];
    if (location?.state?.date && data !== 'today') {
      currentDate = [location?.state?.date, location?.state?.date];
    } else {
      currentDate = [
        DateTime.now().startOf("day").toFormat("yyyy-MM-dd"),
        DateTime.now().endOf("day").toFormat("yyyy-MM-dd"),
      ];
    }

    let payload: AbnormalFilter[] = [
      {
        field: "t.validityEnd",
        value: "undefined",
        operator: "isValid",
      },
      {
        field: "data.admission_date",
        operator: "isBetween",
        value: currentDate, //["2023-04-06T00:00:00", "2023-04-06T23:59:59"],
        usecase: "date",
      },
      {
        field: "data.surgeryroom_uuid",
        value: CookieService.getSurgeryRoomUUID(),
        operator: "equal",
      },
    ];

    changeShowLoading(SurgeryService.getSurgeries(payload)
      .then((rawData) => {
        const data: DataType[] = _responseFormat(rawData);

        setSurgeries(data);
      })
      .catch((e) => {
        console.log(t('ERROR'));
      }));
  };

  React.useEffect(() => {
    loadSurgeries('another_date');
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row justify="space-between" className="pr-10 pt-4 m-4">
        <div></div>
        <div className="flex justify-between">
          <Button
            type="primary"
            className="customButton mr-8"
            style={{ backgroundColor: styles.orange }}
            onClick={() => navigate("/calendar")}
          >
            {t('CALENDAR')}
          </Button>
          <Button
            type="primary"
            size={"large"}
            className="customButton mr-4"
            style={{ backgroundColor: styles.lightBlueBgColor }}
            onClick={() => loadSurgeries('today')}
          >
            {t('TODAY')}
          </Button>
          {/* <Button
            type="primary"
            size={"large"}
            className="customButton"
            style={{ backgroundColor: styles.blueColor }}
          /> */}
        </div>
      </Row>
      <Table
        className="px-10"
        style={{ paddingTop: "18px", height: '60vh' }}
        columns={columns}
        rowClassName="surgery-row"
        expandable={{
          expandedRowRender: (record) => {
            const allDiagnosis = record.diagnosis
              .map((el) => el.description)
              .join(" - ");
            const allOperators = record.operators
              .map((el) => el.description)
              .join(" - ");
            return (
              <p
                style={{ margin: 0, color: styles.blueColor }}
                className="grid grid-cols-5 gap-4 font-semibold"
              >
                <div className="col-span-2 text-lg">{t('GUARD_BED')}:</div>
                <div className="col-span-3 text-base">
                  {record?.wardBed?.code
                    ? record.wardBed.code + " - " + record.wardBed.description
                    : ""}
                </div>

                <div className="col-span-2 text-lg">{t('DESTINATION_BED')}:</div>
                <div className="col-span-3 text-base">
                  {record?.destinationBed?.code
                    ? record.destinationBed.code +
                      " - " +
                      record.destinationBed.description
                    : ""}
                </div>

                <div className="col-span-2 text-lg">{t('DIAGNOSIS')}:</div>
                <div className="col-span-3 text-base">{allDiagnosis}</div>

                <div className="col-span-2 text-lg">{t('POSITION')}:</div>
                <div className="col-span-3 text-base">{record.position.code} {record.position.description}</div>

                <div className="col-span-2 text-lg">{t('CONTAMINATED')}:</div>
                <div className="col-span-3 text-base">{record.contaminated.code} {record.contaminated.description}</div>

                <div className="col-span-2 text-lg">{t('THERAPIES')}:</div>
                <div className="col-span-3 text-base">{record.therapies}</div>

                <div className="col-span-2 text-lg">{t('AMNESIS')}:</div>
                <div className="col-span-3 text-base">{record.anamnesis}</div>

                <div className="col-span-2 text-lg">{t('OPERATOR')}:</div>
                <div className="col-span-3 text-base">{allOperators}</div>

                <div className="col-span-2 text-lg">{t('NOTE')}:</div>
                <div className="col-span-3 text-base">{record.note}</div>
              </p>
            );
          },
        }}
        pagination={{ pageSize: 1000 }}
        dataSource={surgeries}
        scroll={scroll}
      />
    </>
  );
}

export default Surgeries;

function _responseFormat(rawData: SurgeryManagment[]): DataType[] {
  return rawData.map((surgeryMgm, index) => {
    return {
      uuid: surgeryMgm.uuid,
      counterBar: surgeryMgm.counterBar,
      key: index,
      patientUuid: surgeryMgm.patient.uuid,
      firstName: surgeryMgm.patient.first_name,
      lastName: surgeryMgm.patient.last_name,
      tin: surgeryMgm.patient.tin,
      birthDay: surgeryMgm.patient.date_of_birth,
      counterValue: surgeryMgm.counterValue,
      revision: surgeryMgm.revision.revisionNumber,
      operators: surgeryMgm.operators.map((el) => {
        return {
          code: el.info[0]?.code,
          description: el.info[0]?.description,
          first_name: el.info[0]?.first_name,
          last_name: el.info[0]?.last_name,
        };
      }),
      diagnosis: surgeryMgm.diagnosis.map((el) => {
        return {
          code: el.info[0]?.code,
          description: el.info[0]?.description,
        };
      }),
      surgeryDate: surgeryMgm.revisionData[0].admissionDate,
      slotStart: surgeryMgm.revisionDetail[0].start_date,
      therapies: surgeryMgm.revisionData[0].therapies,
      anamnesis: surgeryMgm.revisionData[0].anamnesis,
      contaminated: surgeryMgm.contaminated[0],
      position: surgeryMgm.surgeryPosition[0],
      note: surgeryMgm.revisionData[0].note,
      status: surgeryMgm.revision.currentStatus,
      surgery: {
        code: surgeryMgm.surgery.length ? surgeryMgm.surgery[0].code : "",
        description: surgeryMgm.surgery.length
          ? surgeryMgm.surgery[0].description
          : "",
      },
      wardDept: {
        code: surgeryMgm.wardDepartment.length
          ? surgeryMgm.wardDepartment[0].code
          : "",
        description: surgeryMgm.wardDepartment.length
          ? surgeryMgm.wardDepartment[0].description
          : "",
      },
      wardBed: {
        code: surgeryMgm.wardBed.length ? surgeryMgm.wardBed[0].code : "",
        description: surgeryMgm.wardBed.length
          ? surgeryMgm.wardBed[0].description
          : "",
      },
      destinationBed: {
        code: surgeryMgm.destinationBed.length
          ? surgeryMgm.destinationBed[0].code
          : "",
        description: surgeryMgm.destinationBed.length
          ? surgeryMgm.destinationBed[0].description
          : "",
      },
    };
  });
}
