import { AbnormalFilter } from "../types/common.types";
import { SurgeriesChecklist, SurgeryManagment } from "../types/surgeries.types";
import { baseHttp } from "./baseHttp";

const getSurgeries = async (payload: AbnormalFilter[]): Promise<SurgeryManagment[]> => {
  return baseHttp.post(`/surgery-management/monitor/filter?q=1&page=1&take=1000&order=data.admissionDate:ASC`, payload).then((response) => response.data.list);
};

const getChecklistExist = async (uuid: string): Promise<SurgeriesChecklist> => {
  return baseHttp.get(`/surgery-management/checklists/exist/${uuid}`).then((response) => response.data);
};

const SurgeryService = {
  getSurgeries,
  getChecklistExist
}

export default SurgeryService;

