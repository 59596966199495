export enum OperatorPosition {
  FIRST = "first",
  SECOND = "second",
  THIRD = "third",
  FOURTH = "fourth",
  FIFTH = "fifth",
  SIXTH = "sixth",
  SEVENTH = "seventh",
  EIGTHTH = "eigthth",
  NINTH = "ninth",
  TENTH = "tenth",
}


export enum ActionDateType {
  IN = "in",
  OUT = "out",
}