import { DateTime } from "luxon";
import { baseHttp } from "./baseHttp";

const load = async (payload: {
  uuid: string;
  tabName: string;
}) => {
  return baseHttp
    .post(`/surgery-management-revision/last/revision/monitor`, payload)
    .then((response: any) => response.data);
};

const update = async (surgeryUUID: string, payload: {
  tabName: string;
  content: {
    checklist: string;
    startTime: string | null;
    endTime: string | null;
    startOperator: string;
    endOperator: string;
    completed: boolean;
    checkpoints: Array<{
      checkpoint: string;
      type: string;
      question: string;
      submittedAnswers: string | Array<string> | DateTime;
    }>;
  }
}) => {
  return baseHttp
    .post(`/surgery-management/monitor/update/${surgeryUUID}`, payload)
    .then((response: any) => response.data);
};

const ChecklistService = {
  load,
  update,
}

export default ChecklistService;
