import { atom, selector } from 'recoil';
import { UserInfoResponse } from '../../types/login.types';

export const userInfoState = atom({
    key: 'userInfoState',
    default: <UserInfoResponse>{
        capabilities: {},
        matrixPar: {},
        userInfo: {}
    },
});


export const getUserInfoState = selector({
    key: 'getUserInfoState', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        const value = get(userInfoState);
        return value;
    },
});