import { UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import background from "../../assets/images/settings_background.jpg";
import { changeShowLoading } from "../../components/Spinner/Spinner";
import { DBKeys, DBTables } from "../../enums/db.enum";
import { db } from "../../persistance/db";
import AuthService from "../../services/auth.service";
import CookieService from "../../services/cookie.service";
import { SettingsData } from "../../types/Setting.types";
import { DivisionType, SurgeryRoomType } from "../../types/general.types";
import "./Settings.css";

const { Title } = Typography;
// import css

function Settings() {
  let navigate = useNavigate();

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [surgeryRoomList, setSurgeryRoomList] = useState(
    [] as SurgeryRoomType[]
  );
  const [divisionList, setDivisionList] = useState([] as Array<any>);
  const [division, setDivision] = useState({} as DivisionType);
  const [surgeryRoom, setSurgeryRoom] = useState({} as SurgeryRoomType);

  const onApiUrlBlurOutside = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    CookieService.setApiUrl(newValue);
    loadDivisionList();
  };

  const loadDivisionList = () => {
    changeShowLoading(
      AuthService.retrieveDivisionList().then((resp) => {
        setDivisionList(resp.list);
      })
    );
  };

  const loadSurgeryRoomList = () => {
    changeShowLoading(
      AuthService.retrieveSurgeryRoomList().then((resp) => {
        setSurgeryRoomList(resp.list);
      })
    );
  };

  const saveSettings = async () => {
    const formData = form.getFieldsValue();
    const settings = await db.settings.get(DBKeys.SYSCONFIG);

    const payload = {
      key: DBKeys.SYSCONFIG,
      urlApi: formData.urlApi,
      surgeryRoomUUID: formData.surgeryRoomUUID,
      surgeryRoomDescription: surgeryRoom.description || "",
      surgeryRoomBlocksUUID:
        surgeryRoom.surgery_room_blocks?.surgery_room_block_uuid || "",
      divisionUUID: formData.divisionUUID,
      divisionDescription: division.description || "",
    };

    if (settings) {
      await db.settings.put(payload);
    } else {
      await db.settings.add(payload);
    }
    navigate("/login");
  };

  const cancelSettings = async () => {
    const settings = await db.settings.get(DBKeys.SYSCONFIG);
    if (settings) {
      // remove settings
      await db.settings.delete(DBKeys.SYSCONFIG);
      //reset form
      form.resetFields();
    } else {
      console.warn("settings not found");
    }
  };

  const setConnectionString = async (value: any) => {
    if (value.target.value) {
      let cs: SettingsData = JSON.parse(value.target.value);
      form.setFieldsValue(cs);
    }
  };

  const restoreData = async () => {
    // restore from db into form
    const settings = await db.settings.get(DBKeys.SYSCONFIG);
    console.log("settings", settings);

    if (settings && settings.urlApi) {
      // Prendo l'indirizzo dell'API dal DB
      CookieService.setApiUrl(settings.urlApi);

      const surgeryRoomListResponse =
        await AuthService.retrieveSurgeryRoomList().then((resp) => resp.list);

      const divisionListResponse =
        await AuthService.retrieveDivisionList().then((resp) => resp.list);
      
      if (divisionListResponse) {
        setDivisionList(divisionListResponse);
      }

      if (surgeryRoomListResponse) {
        setSurgeryRoomList(surgeryRoomListResponse);
        form.setFieldsValue(settings);
      }
    } else {
      console.warn("settings not found");
    }
  };

  const onSurgeryRoomBlurOutside = (e: string) => {
    CookieService.setSurgeryRoomUUID(e);
    const choosenRoom: SurgeryRoomType = surgeryRoomList.filter(
      (el: SurgeryRoomType) => el.uuid === e
    )?.[0];

    setSurgeryRoom(choosenRoom);
    console.log("choosenRoom", choosenRoom);

    CookieService.setSurgeryRoomDescription(choosenRoom.description);

    let choosenRoomSrbUUID = choosenRoom.surgery_room_blocks?.surgery_room_block_uuid || "";

    CookieService.setSurgeryRoomBlocksUUID(choosenRoomSrbUUID);
  };

  const onDivisionBlurOutside = (e: string) => {
    let choosenDivision: DivisionType = divisionList.filter(
      (el: DivisionType) => el.uuid === e
    )?.[0];

    setDivision(choosenDivision);
    CookieService.setDivisionUUID(choosenDivision.uuid);
    loadSurgeryRoomList();
    // reset surgery room on dexie db 
    form.setFieldsValue({ surgeryRoomUUID: "" });
    CookieService.setSurgeryRoomUUID("");
    CookieService.setSurgeryRoomDescription("");
  };

  const dropDatabase = async () => {
    await db.deleteTable(DBTables.SETTINGS);
  };

  // restore data from db
  React.useEffect(() => {
    restoreData();
  }, []);

  return (
    <>
      <div>
        <div style={{ zIndex: "1", backgroundColor: "white" }}>
          <Title level={1} className="ant-table-cell title">
            {t("SETTINGS")}
          </Title>
          <Form name="settings-form" className="mx-28 formCell" form={form}>
            <Form.Item
              name="urlApi"
              label="Url API"
              rules={[
                {
                  required: true,
                  message: "Please input URL API!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                onBlur={(e) => onApiUrlBlurOutside(e)}
                style={{ minWidth: "20rem" }}
              />
            </Form.Item>

            <Form.Item
              name="divisionUUID"
              label="Division"
              rules={[
                {
                  required: true,
                  message: "Please select a division!",
                },
              ]}
            >
              <Select
                showSearch
                className="w-full"
                optionFilterProp="children"
                fieldNames={{ label: "description", value: "uuid" }}
                filterOption={(input, option: any) =>
                  option.description
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                options={divisionList}
                style={{ minWidth: "20rem" }}
                onChange={onDivisionBlurOutside}
              />
            </Form.Item>

            <Form.Item
              name="surgeryRoomUUID"
              label="Surgery Room"
              rules={[
                {
                  required: true,
                  message: "Please input surgery room!",
                },
              ]}
            >
              <Select
                showSearch
                className="w-full"
                optionFilterProp="children"
                fieldNames={{ label: "description", value: "uuid" }}
                filterOption={(input, option: any) =>
                  option.description
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                options={surgeryRoomList}
                style={{ minWidth: "20rem" }}
                onChange={onSurgeryRoomBlurOutside}
              />
            </Form.Item>

            <Form.Item name="setOthersFields">
              <Input.TextArea
                className="w-full"
                style={{ minWidth: "20rem", minHeight: "10rem" }}
                onBlur={(e) => setConnectionString(e)}
              />
            </Form.Item>

            <div>
              <div className="mt-8 space-y-2">
                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-full bg-green-500 "
                    onClick={saveSettings}
                  >
                    {t("CONFIRMED")}
                  </Button>
                </div>
                <div>
                  <Button
                    type="primary"
                    onClick={() => navigate("/login")}
                    className="w-full bg-blue-500"
                  >
                    {t("BACK")}
                  </Button>
                </div>
              </div>

              <div className="mt-8 space-y-2">
                <div>
                  <Button
                    type="primary"
                    className="w-full bg-red-500"
                    onClick={cancelSettings}
                  >
                    {t("CANCEL_SETTINGS")}
                  </Button>
                </div>
                <div>
                  <Button
                    type="primary"
                    onClick={dropDatabase}
                    className="w-full bg-blue-500"
                  >
                    {t("RESET_DB_SETTINGS")}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>

        <div style={{ zIndex: "0" }}>
          <img src={background} className="backGround" alt="" />
          <div className="fade"></div>
        </div>
      </div>
    </>
  );
}

export default Settings;
