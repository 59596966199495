import "./Login.css";
import { Button, Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import background from "../../assets/images/background.jpg";
import {
  SecretCounter,
  LoginForm,
  User,
  UserInfoResponse,
  UserRoleResponse,
} from "../../types/login.types";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { genericResponse as GenericResponse } from "../../types/axios.types";
import { useRecoilState } from "recoil";
import { isAuthenticatedState } from "../../recoil/atoms/authenticationAtom";
import { Collapse } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { userInfoState } from "../../recoil/atoms/userInfoAtom";
import CookieService from "../../services/cookie.service";
import { SurgeryRoomType } from "../../types/general.types";
import sixConfig from "../../sixConfig";
import { imgHandler } from "../../ImgHandler";
import { changeShowLoading } from "../../components/Spinner/Spinner";

const { Panel } = Collapse;


function Login() {
  let navigate = useNavigate();
  
  // eslint-disable-next-line
  const [_, setIsAuthenticated] = useRecoilState(isAuthenticatedState);
  // eslint-disable-next-line
  const [__, setUserInfo] = useRecoilState(userInfoState);
  // eslint-disable-next-line
  const [___, setSelectedUser] = useState({} as User);
  const [secretCounter, setSecretCounter] = useState({} as SecretCounter);
  const [userRoles, setUserRoles] = useState({} as UserRoleResponse);
  const { t, i18n } = useTranslation();
  const [hideUrlBe, setHideUrlBe] = useState(false);
  const [isConfigurationModalOpen, setIsConfigurationModalOpen] =
    useState(false);
  const [surgeryRoomList, setSurgeryRoomList] = useState(
    [] as SurgeryRoomType[]
  );

  const [form] = Form.useForm();

  const customer = sixConfig.APP_CUSTOMER;
  const onFinish = async (values: LoginForm) => {
    const login: GenericResponse = await AuthService.loginRequest(values);
    if (login.status === 200) {
      setIsAuthenticated(true);
      const userInfo: UserInfoResponse =
        await AuthService.retrieveLoggedUserInfo();
      setUserInfo(userInfo);
      navigate("/surgeries");
    }
  };

  const loadUserRole = async () => {
   
    changeShowLoading(AuthService.retrieveUserInRole()
      .then((response) => {
        setUserRoles(response);
      })
      .catch((e) => {
        console.log(e);
      }));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onSelectedUserChanged = (user: User) => {
    form.setFieldsValue({ username: user.username });
    setSelectedUser(user);
  };

  const onLogoClick = () => {
    const currentCounter = secretCounter?.counter || 1;
    if (currentCounter === 3) {
      setSecretCounter({ counter: 1 });
      navigate("/settings");
    } else {
      setSecretCounter({ counter: currentCounter + 1 });
    }
  };

  /**
   * MODAL start
   */



  const handleOk = () => {
    setIsConfigurationModalOpen(false);
  };

  const handleCancel = () => {
    setIsConfigurationModalOpen(false);
  };


  const loadSurgeryRoomList = () => {
    changeShowLoading(AuthService.retrieveSurgeryRoomList().then((resp) => {
      setSurgeryRoomList(resp.list);
    }));
  };


  const initMonitorParams = async () => {
    const queryParams = new URLSearchParams(window.location.search)
    const urlBe = queryParams.get("url_be")
    if(urlBe){
      setHideUrlBe(true);
      CookieService.setApiUrl(urlBe);
    }
  };

  /**
   * MODAL end
   */

  useEffect(() => {
    initMonitorParams();
    const apiUrl = CookieService.getApiUrl();
    if (!apiUrl || apiUrl === "") {
      return;
    }  
    loadUserRole();
  }, []);

  return (
    <>
      <div className="grid grid-cols-12 h-screen">
        <div className="background-image col-span-8 bg-gradient-to-r from-sky-500 to-indigo-500 relative">
          <img src={background} className="w-full h-full bg-no-repeat" alt="" />
          <div className="w-1/2 h-1/2 absolute top-1/3 left-1/4 rounded-lg p-4">
            <Collapse accordion>
              {Object.keys(userRoles).map((key, index) => {
                return (
                  <Panel
                    showArrow={false}
                    className="panel-header"
                    header={t(key.toUpperCase())}
                    key={index}
                  >
                    {userRoles[key].map((user: User) => {
                      return (
                        <div>
                          <Button
                            type="primary"
                            className="panel-button"
                            block
                            onClick={() => onSelectedUserChanged(user)}
                          >
                            {user.firstName} {user.lastName}
                          </Button>
                        </div>
                      );
                    })}
                  </Panel>
                );
              })}
            </Collapse>
          </div>
        </div>
        <div className="col-span-4 bg-white">
          <div className="login-form">
            <Form
              name="normal_login"
              className="login-form mx-28 my-80 w-80 h-80"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
            >
              <Form.Item>
                <div
                  className="logo-container flex justify-center"
                  onClick={onLogoClick}
                >
                  <img src={require(`../../assets/images/${imgHandler?.[customer]?.src_login_top}.jpg`)} className={`${imgHandler?.[customer]?.style_top}`} alt="" />
                </div>
              </Form.Item>
              <Form.Item
                className="w-72 ml-4"
                name="username"
                rules={[
                  {
                    required: true,
                    message: `${t('ENTER_USERNAME')}`,
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={`${t('USERNAME')}`}
                />
              </Form.Item>
              <Form.Item
                className="w-72 ml-4"
                name="password"
                rules={[
                  {
                    required: true,
                    message: `${t('ENTER_PASSWORD')}`,
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder={`${t('PASSWORD')}`}
                />
              </Form.Item>
              <Form.Item>
                <div className="flex justify-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button bg-cyan-500 w-28 font-bold"
                  >
                    {t('LOG_IN')}
                  </Button>
                </div>
              </Form.Item>
              <Form.Item>
                <div className="logo-container flex justify-center">
                  {imgHandler[customer].src_login_btm ? <>
                    <img src={require(`../../assets/images/${imgHandler?.[customer]?.src_login_btm}.jpg`)} className={`${imgHandler?.[customer]?.style_btn}`} alt="" />
                  </> : ''}
                </div>
              </Form.Item>
              <div id="version" className="flex justify-center text-lg">
                <div className="flex-col">
                  <div>{sixConfig?.APP_NAME}  v.{sixConfig?.VERSION}</div>
                  <div className="text-sm text-gray-600">{sixConfig?.REV} ({sixConfig?.BRANCH}) </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
