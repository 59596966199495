import React, { useEffect, useRef, useState } from "react";
import "./Countdown.css";
import { Button, Space, Typography } from "antd";
import { DateTime } from "luxon";

import {
  PauseOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
const { Title } = Typography;

const Countdown = (payload: {
  countdownTimer: number;
  rowId: string;
  setProps: any;
  data: any;
}) => {
  const [timer, setTimer] = useState(payload.data.value); // seconds
  const [isRunning, setIsRunning] = useState(payload.data.isRunning);

  const firstStart = useRef(true);
  const tick = useRef({} as ReturnType<typeof setInterval> | undefined);

  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = !firstStart.current;
      payload.setProps({
        rowId: payload.rowId,
        isRunning: isRunning,
        value: timer,
        now: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
      });
      return;
    }

    let updateId: any = null;

    if (isRunning) {
      tick.current = setInterval(() => {
        setTimer((timer: number) => timer > 0 ? timer - 1 : 0);
      }, 1000);
    } else {
      payload.setProps({
        rowId: payload.rowId,
        isRunning: isRunning,
        value: timer,
        now: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
      });
      clearInterval(tick.current);
      clearInterval(updateId);
    }

    return () => clearInterval(tick.current);
  }, [isRunning]);

  const toggleStart = () => {
    setIsRunning(!isRunning);
  };

  const dispSecondsAsMins = (totalSeconds: number) => {
    // 25:00
    let hour = Math.trunc(totalSeconds / 3600);
    let minute = Math.trunc((totalSeconds - hour * 3600) / 60);
    let second = totalSeconds - hour * 3600 - minute * 60;

    return (hour > 10 ? hour.toString() : '0' + hour.toString()) + ':' + (minute > 10 ? minute.toString() : '0' + minute.toString()) + ':' + (second > 10 ? second.toString() : '0' + second.toString());
  };

  const reset = () => {
    setTimer(payload.data.oldValue);
  };

  React.useEffect(() => {
    payload.setProps({
      rowId: payload.rowId,
      isRunning: isRunning,
      dateStarted: payload.data.dateStarted,
      value: timer,
      now: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
    });
  }, [timer]);
  
  return (
    <>
      <div>
        <Space>
          <Title level={4}>
            {dispSecondsAsMins(timer)}
          </Title>
          <Button
            type="primary"
            icon={isRunning ? <PauseOutlined /> : <PlayCircleOutlined />}
            onClick={toggleStart}
          />
          <Button type="primary" icon={<ReloadOutlined />} onClick={reset} />
        </Space>
      </div>
    </>
  );
};

export default Countdown;
