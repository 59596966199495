import DatePicker from "../DatePicker/DatePicker";
import type { PickerTimeProps } from "antd/es/date-picker/generatePicker";
import type { DateTime } from "luxon";
import * as React from "react";
import "./TimePicker.css";

export interface TimePickerProps
  extends Omit<PickerTimeProps<DateTime>, "picker"> {}

const TimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => (
  <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
));

TimePicker.displayName = "TimePicker";

export default TimePicker;
