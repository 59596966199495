import { DeleteOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Row, Select, Space, Switch, Alert } from "antd";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { ActionDateType, OperatorPosition } from "../../enums/equipe.enum";
import styles from "../../index.style";
import { sharedInternalInfoState } from "../../recoil/atoms/sharedInternalAtom";
import CookieService from "../../services/cookie.service";
import EquipeService from "../../services/equipe.service";
import EquipmentService from "../../services/equipe.service";
import { EquipeUser, EquipeUserPayload } from "../../types/equipe.type";
import { User, UserRoleResponse } from "../../types/login.types";
import "./Equipe.css";
import TimePicker from "../../components/TimePicker/TimePicker";
import { userInfoState } from "../../recoil/atoms/userInfoAtom";
import { useTranslation } from "react-i18next";
import { changeShowLoading } from "../../components/Spinner/Spinner";

const { Panel } = Collapse;

function Equipe() {
  const { t, i18n } = useTranslation();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [userRoles, setUserRoles] = useState({} as UserRoleResponse);
  let [equipeList, setEquipeList] = useState([] as EquipeUser[]);
  let [shouldSaveData, setShouldSaveData] = useState(false);
  const [sharedInfo] = useRecoilState(sharedInternalInfoState);
  const [positionList, setPositionList] = useState(
    Array<{ key: string; description: string }>() as Array<{
      key: string;
      description: string;
    }>
  );
  const [enableExit, setEnableExit] = useState(false);

  const [rowFE, setRowFE] = useState(0);
  const loadOperators = async (showOnlySurgeryRoomBlock: boolean = false) => {
    let surgeryRoomBlockUUID = null;
    if (showOnlySurgeryRoomBlock) {
      surgeryRoomBlockUUID = CookieService.getSurgeryRoomBlocksUUID();
    }

    changeShowLoading(EquipmentService.retrieveUserInRole(surgeryRoomBlockUUID)
      .then((response) => {
        setUserRoles(response);
      })
      .catch((error) => {
        console.error(error);
      }));
  };

  const initData = async () => {
    changeShowLoading(EquipeService.loadData({
      uuid: sharedInfo.surgery,
      tabName: "operators-tab",
    }).then((resp) => {
      setEquipeList(resp?.operator);
    }));
  };

  const onSelectedUserChanged = (user: User) => {
    const equipe: EquipeUser = {
      uuid: user.operatoruuid,
      user: user,
      operatorPosition: OperatorPosition.FIRST,
      index: equipeList.length,
      inDate: null,
      outDate: null,
      row: `${rowFE}`,
      operatorType: user.operatorTypeuuid,
      description: user.operatorDescription,
      operatorDescription: user.operatorDescription,
      operatorTypeInfo: {
        active: true,
        code: user.operatorTypeCode,
        description: user.operatorTypeDescription,
        notes: null,
        validityEnd: null
      }
    };

    setRowFE(rowFE + 1);
    equipeList.push(equipe);
    equipeList = [...equipeList];
    setEquipeList(equipeList);
    setShouldSaveData(true);
  };

  const setTeporalMarker = (
    event: DateTime | null,
    userEquipe: EquipeUser,
    type: string
  ) => {
    const now = event?.toFormat("yyyy-MM-dd HH:mm:ss");
    const findOperatorIndex = equipeList.findIndex(
      (el) => el.row === userEquipe.row
    );
    if (type === "in") {
      equipeList[findOperatorIndex] = {
        ...userEquipe,
        inDate: now ? now : null,
      };
    } else {
      equipeList[findOperatorIndex] = {
        ...userEquipe,
        outDate: now ? now : null,
      };
    }

    equipeList = [...equipeList];
    setEquipeList(equipeList);
    setShouldSaveData(true);
  };

  const saveData = () => {
    const payload: EquipeUserPayload[] = equipeList.map((userEquipe) => {
      return {
        operator: userEquipe.uuid,
        operatorPosition: userEquipe.operatorPosition,
        inDate: userEquipe.inDate,
        outDate: userEquipe.outDate,
        operatorType: userEquipe.operatorType,
      };
    });

    if (
      userInfo.capabilities["CAN_EDIT_ALL_TAB"] ||
      userInfo.capabilities["CAN_EDIT_OPERATORS_TAB"]
    ) {
      changeShowLoading(EquipeService.update(sharedInfo.surgery, {
        tabName: "operators-tab",
        content: payload,
      })
        .catch((error) => {
          console.error(`${t("ERROR_WHILE_SAVE_DATA")} ${error}`);
        })
        .finally(() => {
          setShouldSaveData(false);
        }));
    } else {
      <Alert
        message={t("PERMISSION_NEGATE")}
        type="warning"
        showIcon
        closable
      />;
    }
  };

  const deleteEquipeUser = (userEquipe: EquipeUser) => {
    equipeList = equipeList.filter((u) => u.row !== userEquipe.row);
    setEquipeList(equipeList);
    setShouldSaveData(true);
  };

  const initPositionList = () => {
    // foreach item of OperatorPosition enum, push the value in positionList
    for (let item in OperatorPosition) {
      positionList.push({
        key: OperatorPosition[item as keyof typeof OperatorPosition],
        description: translatePosition(item), //TODO translate
      });
    }
    setPositionList(positionList);
  };

  const translatePosition = (item: string) => {
    let translate = "";
    switch (item) {
      case "FIRST":
        translate = `${t("FIRST")}`;
        break;
      case "SECOND":
        translate = `${t("SECOND")}`;
        break;
      case "THIRD":
        translate = `${t("THIRD")}`;
        break;
      case "FOURTH":
        translate = `${t("FOURTH")}`;
        break;
      case "FIFTH":
        translate = `${t("FIFTH")}`;
        break;
      case "SIXTH":
        translate = `${t("SIXTH")}`;
        break;
      case "SEVENTH":
        translate = `${t("SEVENTH")}`;
        break;
      case "EIGTHTH":
        translate = `${t("EIGTHTH")}`;
        break;
      case "NINTH":
        translate = `${t("NINTH")}`;
        break;
      case "TENTH":
        translate = `${t("TENTH")}`;
        break;
    }
    return translate;
  };

  const changePosition = (
    value: any,
    userEquipe: EquipeUser,
    index: number
  ) => {
    equipeList[index] = {
      ...userEquipe,
      operatorPosition: value,
    };
    setEquipeList(equipeList);
    setShouldSaveData(true);
  };

  const applyMassiveDate = (type: ActionDateType) => {
    const now = DateTime.local().toFormat("yyyy-MM-dd HH:mm:ss");

    equipeList = equipeList.map((userEquipe) => {
      if (type === ActionDateType.IN) {
        userEquipe.inDate = userEquipe.inDate ? userEquipe.inDate : now;
      } else if (type === ActionDateType.OUT && userEquipe.inDate) {
        userEquipe.outDate = userEquipe.outDate ? userEquipe.outDate : now;
      }
      return userEquipe;
    });

    setEquipeList(equipeList);
    setShouldSaveData(true);
  };

  const loadOffBlockUser = (showOnlySurgeryRoomBlock: boolean) => {
    loadOperators(showOnlySurgeryRoomBlock);
  };

  const setLongDescription = (key: string) => {
    let longDescription = key.toUpperCase() + "_LONG_DESCRIPTION";
    return t(longDescription);
  };

  const controlDisabled = () => {
    let enabled = true;

    equipeList.map((element: any) => {
      if (element.inDate) {
        enabled = false;
      }
    });

    setEnableExit(enabled);
  };

  React.useEffect(() => {
    controlDisabled();
  }, [equipeList]);

  React.useEffect(() => {
    initPositionList();
    loadOperators(true);
    initData();
  }, []);

  React.useEffect(() => {
    if (shouldSaveData) {
      saveData();
    }
  }, [shouldSaveData]);

  return (
    <>
      <div className="flex justify-between mb-4">
        <Switch
          defaultChecked
          onChange={loadOffBlockUser}
          title="block"
          checkedChildren={t("EN_BLOC")}
          unCheckedChildren={t("OUT_OF_BLOCK")}
          className="equipe-switch"
          style={{ backgroundColor: styles.blueColor }}
        />
        <Space size={20}>
          <Button
            type="primary"
            className="customButton"
            style={{ backgroundColor: styles.blueColor }}
            block
            onClick={() => applyMassiveDate(ActionDateType.IN)}
            disabled={equipeList.length > 0 ? false : true}
          >
            {t("ENTRANCE")}
          </Button>
          <Button
            type="primary"
            className="customButton"
            style={{ backgroundColor: styles.blueColor }}
            block
            onClick={() => applyMassiveDate(ActionDateType.OUT)}
            disabled={enableExit}
          >
            {t("EXIT")}
          </Button>
        </Space>
      </div>
      <Row gutter={[48, 16]}>
        <Col span={8}>
          <Collapse accordion>
            {Object.keys(userRoles)
              .sort()
              .map((key, index) => {
                return (
                  <Panel
                    showArrow={false}
                    className="panel-header"
                    header={key ? setLongDescription(key) : key}
                    key={index}
                  >
                    {userRoles[key].map((user: User) => {
                      return (
                        <div>
                          <Button
                            type="primary"
                            className="panel-button"
                            block
                            onClick={() => onSelectedUserChanged(user)}
                          >
                            {user.operatorDescription}
                          </Button>
                        </div>
                      );
                    })}
                  </Panel>
                );
              })}
          </Collapse>
        </Col>

        {/* LATO DESTRO OPERATORI IN INGRESSO / USCITA */}
        <Col span={16} className="mt-4 overflow-y h-3/5">
          <div className="mb-4">
            <Row gutter={[12, 16]}>
              <Col span={5} className="text-xl list-operator-name"> {t("USERNAME")}</Col>
              <Col span={6} className="text-xl list-operator-name">{t("POSITION")}</Col>
              <Col span={6} className="text-xl list-operator-name">{t("ENTRANCE")}</Col>
              <Col span={6} className="text-xl list-operator-name">{t("EXIT")}</Col>
              <Col span={1} className="text-xl list-operator-name"></Col>
            </Row>
          </div>
          <hr/>
          {equipeList.map((userEquipe, index) => {
            return (
              <div className="mb-4">
                <Row gutter={[12, 16]}>
                  <Col span={5} className="list-operator-name">
                    {userEquipe.operatorDescription || userEquipe.description} <span className="text-sm">({userEquipe.operatorTypeInfo?.code || t("ND")})</span>
                  </Col>
                  <Col span={6}>
                    <Select
                      className="w-full"
                      optionFilterProp="children"
                      fieldNames={{
                        label: "description",
                        value: "key",
                      }}
                      onChange={(value) => {
                        changePosition(value, userEquipe, index);
                      }}
                      filterOption={(input, option: any) =>
                        option.description
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={userEquipe.operatorPosition}
                      options={positionList}
                    />
                  </Col>
                  <Col span={6}>
                    <TimePicker
                      className="w-full"
                      format="HH:mm:ss"
                      onSelect={(event) =>
                        setTeporalMarker(event, userEquipe, "in")
                      }
                      value={
                        userEquipe.inDate
                          ? DateTime.fromFormat(
                              userEquipe.inDate,
                              "yyyy-MM-dd HH:mm:ss"
                            )
                          : null
                      }
                      clearIcon={<CloseCircleOutlined onClick={() => setTeporalMarker(null, userEquipe, "in")}/>}
                    />
                  </Col>
                  <Col span={6}>
                    <TimePicker
                      className="w-full"
                      format="HH:mm:ss"
                      onSelect={(event) =>
                        setTeporalMarker(event, userEquipe, "out")
                      }
                      value={
                        userEquipe.outDate
                          ? DateTime.fromFormat(
                              userEquipe.outDate,
                              "yyyy-MM-dd HH:mm:ss"
                            )
                          : null
                      }
                      disabled={userEquipe.inDate ? false : true}
                      clearIcon={<CloseCircleOutlined onClick={() => setTeporalMarker(null, userEquipe, "out")}/>}
                    />
                  </Col>
                  <Col span={1}>
                    <Button
                      type="primary"
                      icon={<DeleteOutlined />}
                      style={{
                        backgroundColor: styles.red,
                        padding: "5px",
                      }}
                      className="customButtonNoFloat"
                      onClick={() => deleteEquipeUser(userEquipe)}
                    />
                  </Col>
                </Row>
              </div>
            );
          })}
        </Col>
      </Row>
    </>
  );
}

export default Equipe;
