import {
  Button,
  Modal,
  Form,
  Table,
  Input,
  Radio,
  RadioChangeEvent,
} from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import Countdown from "../../components/Countdown/Countdown";
import Stopwatch from "../../components/Stopwatch/Stopwatch";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { DeleteOutlined, PlusOutlined, LeftOutlined } from "@ant-design/icons";
import styles from "../../index.style";
import TimePicker from "../../components/TimePicker/TimePicker";
import { TimerForm, TimerRow } from "../../types/timer.types";
import { currentActiveTimerState } from "../../recoil/atoms/timersAtom";
import { useNavigate } from "react-router";

function Timers() {
  const [tableData, setTableData] = useRecoilState(currentActiveTimerState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timerType, setTimerType] = useState("");
  const [expirationDate, setExpirationDate] = useState(false);
  const [tableDataStatic, setTableDataStatic] = useState([{
      description: '',
      expirationDate: '',
      type: '',
      index: 0,
      isRunning: false,
      dateStarted: '',
      value: 0,
      oldValue: 0,
  }]);
  const [firstTime, setFirstTime] = useState(true);

  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [form] = Form.useForm();

  const labelColSpan = 14;
  const wrapperColSpan = 10;

  const columns = [
    {
      title: `${t('DESCRIPTION')}`,
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "Orario Inizio",
    //   dataIndex: "startDate",
    //   key: "startDate",
    // },
    {
      title: `${t('TYPE')}`,
      dataIndex: "type",
      key: "type",
    },
    // {
    //   title: "Scadenza",
    //   dataIndex: "expirationDate",
    //   key: "expirationDate",
    // },
    // {
    //   title: "Tempo Rimanente",
    //   dataIndex: "lastingTime",
    //   key: "lastingTime",
    // },
    {
      title: " ",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any, rowIndex: number) => (
        <div>
          {record.type === "cronometro" ? (
            <Stopwatch rowId={record.index} setProps={setChildProps} data={record}/>
          ) : (
            <Countdown
              countdownTimer={record.value}
              rowId={`${record.index}`}
              data={record}
              setProps={setChildProps}
            />
          )}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      render: (text: any, record: any, rowIndex: number) => (
        <div>
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            style={{ backgroundColor: styles.red, padding: "7px" }}
            className="customButtonNoFloat"
            onClick={() => deleteTimer(rowIndex + 1)}
          />
        </div>
      ),
    },
  ];

  const addTimer = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    const data: TimerForm = form.getFieldsValue();

    if(timerType === 'countdown'){
      const newCol: TimerRow = {
        description: data.description,
        expirationDate: data.expirationDate?.toFormat("HH:mm:ss"),
        dateStarted: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
        value: convertInSecond(data.expirationDate?.toFormat("HH:mm:ss")),
        oldValue: convertInSecond(data.expirationDate?.toFormat("HH:mm:ss")),
        type: data.type,
        index: tableDataStatic.length,
        isRunning: false,
        now: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
      };
      setTableDataStatic((current) => [...current, ...[newCol]]);
    }else{
      const newCol: TimerRow = {
        description: data.description,
        expirationDate: data.expirationDate?.toFormat("HH:mm:ss"),
        dateStarted: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
        value: convertInSecond('00:00:00'),
        oldValue: convertInSecond('00:00:00'),
        type: data.type,
        index: tableDataStatic.length,
        isRunning: false,
        now: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
      };
      setTableDataStatic((current) => [...current, ...[newCol]]);
    }
    
    resetForm();
    setIsModalOpen(false);
  };

  const convertInSecond = (data: string) => {
    let hours = parseInt(data.substring(0, 2));
    let minutes = parseInt(data.substring(3, 5));
    let seconds = parseInt(data.substring(6, 8));

    hours = hours * 3600;
    minutes = minutes * 60;
    seconds = seconds + minutes + hours;

    return seconds;
  };

  const resetForm = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    resetForm();
    setIsModalOpen(false);
  };

  React.useEffect(() =>  {
    if(!firstTime){
      const tableDataFormat = (tableData || []).map((item: any) => {
        let newValue = 0;
        if(item.isRunning){
          newValue = dispSecondsAsMins(item, item.type);
        }

        const itemFormat: TimerRow = {
          description: item.description,
          expirationDate: item.expirationDate,
          dateStarted: item.dateStarted,
          value: item.isRunning ? newValue : item.value,
          type: item.type,
          index: item.index,
          isRunning: item.isRunning,
          now: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
          oldValue: item.oldValue
        };
        
        return itemFormat;
      });  

      setTableDataStatic(tableDataFormat);
    } else{
      setFirstTime(false);
    }
  }, [firstTime]);

  const buttonExit = () => {
    const currData = tableDataStatic.map((item: any) => {
      let value: TimerRow = {
      description: item.description,
      expirationDate: item.expirationDate,
      dateStarted: item.dateStarted,
      value: item.value,
      type: item.type,
      index: item.index,
      isRunning: item.isRunning,
      now: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
      oldValue: item.oldValue
    };
      return value
    });

    setTableData(currData);
    navigate(-1);
  }

  const deleteTimer = (rowIndex: number) => {
    const currData = [...tableDataStatic];
    currData.splice(rowIndex, 1);
    setTableDataStatic(currData);
  };

  function setChildProps(data: any) {
    const currentData = [...tableDataStatic];
    currentData[data.rowId] = {
      ...currentData[data.rowId],
      ...data,
    };
    setTableDataStatic(currentData);
  }

  const dispSecondsAsMins = (data: any, type: string) => {
    const now = DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss");

    let hoursNow = parseInt(now.substring(11, 13));
    let minuteNow = parseInt(now.substring(14, 16));
    let secondNow = parseInt(now.substring(17, 19));

    secondNow = secondNow + minuteNow * 60 + hoursNow * 3600;

    let hoursStarted = parseInt(data.now.substring(11, 13));
    let minuteStarted = parseInt(data.now.substring(14, 16));
    let secondStarted = parseInt(data.now.substring(17, 19));

    if(parseInt(now.substring(8, 10)) > parseInt(data.now.substring(8, 10))){
      hoursStarted = hoursStarted + 24;
    }

    secondStarted = secondStarted + minuteStarted * 60 + hoursStarted * 3600;

    let value = 0 

    if(type === "countdown"){
      value = data.value - (secondNow - secondStarted); 
    }else if(type === "cronometro"){
      value = data.value + (secondNow - secondStarted)*100; 
    } 

    if(value < 0){
      value = 0
    }

    return value;
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        okButtonProps={{ disabled: expirationDate || timerType  === "cronometro" ? false : true }}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        width={800}
      >
        <div className="form-container">
          <Form name="normal_login" className="login-form" form={form}>
            <Form.Item
              name="description"
              className="form-container-item"
              label={t('DESCRIPTION_TIMER')}
              labelCol={{
                span: labelColSpan,
              }}
              wrapperCol={{
                span: wrapperColSpan,
              }}
              rules={[
                {
                  required: true,
                  message: `${t('PERMISSION_NEGATE')}`,
                },
              ]}
            >
              <Input placeholder={`${t('DESCRIPTION')}`} />
            </Form.Item>

            <Form.Item
              name="type"
              label={t('TIMER_TYPE')}
              className="form-container-item"
              labelCol={{
                span: labelColSpan,
              }}
              wrapperCol={{
                span: wrapperColSpan,
              }}
            >
              <Radio.Group
                className="flex justify-end form-container-radio "
                optionType="button"
                buttonStyle="solid"
                size="large"
                onChange={(e: RadioChangeEvent) =>
                  setTimerType(e.target.value || "")
                }
              >
                <Radio value={"cronometro"}> {t('STOPWATCH')} </Radio>
                <Radio value={"countdown"}> {t('COUNTDOWN')} </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="expirationDate"
              className="form-container-item"
              label={t('DURATION')}
              labelCol={{
                span: labelColSpan,
              }}
              wrapperCol={{
                span: wrapperColSpan,
              }}
            >
              <TimePicker
                disabled={timerType !== "countdown"}
                format="HH:mm:ss"
                onSelect={(e: any) => setExpirationDate(e?.ts ? true : false)}
                changeOnBlur
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <div className="px-10 pt-10">
        <Button
          type="primary"
          className="customButtonNoFloat mb-4"
          onClick={() => buttonExit()}
        >
          <LeftOutlined /> {t('BACK')}
        </Button>
        <Button
          type="primary"
          className="customButton mb-4"
          style={{ backgroundColor: styles.orange }}
          onClick={addTimer}
        >
          <PlusOutlined />
        </Button>

        <Table
          dataSource={tableDataStatic.filter((el) => el.description !== "")}
          columns={columns}
        />
      </div>
    </>
  );
}

export default Timers;
