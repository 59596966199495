import { genericResponse } from "../types/axios.types";
import { DivisionType, SurgeryRoomType } from "../types/general.types";
import { LoginForm, LoginResponse, UserInfoResponse, UserRoleResponse } from "../types/login.types";
import { baseHttp } from "./baseHttp";
import CookieService, { Cookies } from "./cookie.service";

const loginRequest = async (payload: LoginForm): Promise<genericResponse> => {
  const response: LoginResponse = await baseHttp.post(`/user/keycloak/monitor/login`, payload);
  if (response.status === 400) {
    console.log('Errore login: ', response.message)
    return { status: 500, message: response.message };
  }
  if (response.data?.access_token) {
    CookieService.setToken(Cookies.TOKEN, response.data?.access_token, 7);
    return { status: 200, message: '' };
  }
  return { status: 500, message: 'ERROR' };
};

const retrieveUserInRole = async (): Promise<UserRoleResponse> => {
  return baseHttp.get(`/user/all/role`).then((response: any) => response.data);
};

const retrieveLoggedUserInfo = async (): Promise<UserInfoResponse> => {
  return baseHttp.get(`/roles/user/permission`).then((response: any) => response.data)
}

const retrieveSurgeryRoomList = async (): Promise<{ list: SurgeryRoomType[], page: string, pageSize: string, totalResults: number }> => { //Promise<LookupGeneralType[]>
  const payload = [{ field: "t.validityEnd", value: "undefined", operator: "isValid" }]
  return baseHttp.post(`/surgery-room/roomblocks/filter?q=1&take=100&page=1`, payload).then((response: any) => response.data)
}

const retrieveDivisionList = async (): Promise<{ list: DivisionType[], page: string, pageSize: string, totalResults: number }> => { 
  const payload = [{ field: "t.validityEnd", value: "undefined", operator: "isValid" }]
  return baseHttp.post(`/division/filter?q=1&take=100&page=1`, payload).then((response: any) => response.data)
}

const AuthService = {
  loginRequest,
  retrieveUserInRole,
  retrieveLoggedUserInfo,
  retrieveSurgeryRoomList,
  retrieveDivisionList
}

export default AuthService;
