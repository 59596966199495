import { ImgHandler } from "./types/imgHandler.types";

export const imgHandler: ImgHandler = {
    "PUGLIASALUTE" :{
        style_top: "h-24 w-20",
        style_btn: "",
        src_login_top: "PUGLIASALUTE_top",
        src_login_btm: "PUGLIASALUTE_bottom"
    },
    "SISOS": {
        style_top: "h-24 w-30 mr-8",
        style_btn: "",
        src_login_top: "SISOS_top",
        src_login_btm: ""
    }
}