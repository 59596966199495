import React, { useState } from 'react';

function NumericPad({ onResultChange, value } : { onResultChange: (result: string) => void, value?: string }) {

  const [result, setResult] = useState('0');

  const appendToResult = (number: string) => {
    console.log(" MI STA ARRIVANDO IL NUMERO " + number)
    // if number not set return 0
    if (!number) {
      setResult('0');
      return;
    }
    if (result === '0') {
      setResult(number);
      return;
    }
    setResult(prevResult => prevResult + number);
  };


  const cancelLastValue = () => {
    if (result.length === 1) {
      setResult('0');
      return;
    }
    setResult(result.slice(0, -1));
  };


  React.useEffect(() => {
    onResultChange(result);
  }, [result]);

  React.useEffect(() => {
    if (value) {
      setResult(value);
    }
  },[value]);


  return (
    <div className="p-4">
      <input
        type="text"
        className="w-full p-2 mb-4 bg-gray-100 border border-gray-300 rounded"
        value={result}
        readOnly
      />
      <div className="grid grid-cols-3 gap-4">
        <button
          className="p-4 bg-blue-500 text-white font-bold rounded"
          onClick={() => appendToResult('1')}
        >
          1
        </button>
        <button
          className="p-4 bg-blue-500 text-white font-bold rounded"
          onClick={() => appendToResult('2')}
        >
          2
        </button>
        <button
          className="p-4 bg-blue-500 text-white font-bold rounded"
          onClick={() => appendToResult('3')}
        >
          3
        </button>
        <button
          className="p-4 bg-blue-500 text-white font-bold rounded"
          onClick={() => appendToResult('4')}
        >
          4
        </button>
        <button
          className="p-4 bg-blue-500 text-white font-bold rounded"
          onClick={() => appendToResult('5')}
        >
          5
        </button>
        <button
          className="p-4 bg-blue-500 text-white font-bold rounded"
          onClick={() => appendToResult('6')}
        >
          6
        </button>
        <button
          className="p-4 bg-blue-500 text-white font-bold rounded"
          onClick={() => appendToResult('7')}
        >
          7
        </button>
        <button
          className="p-4 bg-blue-500 text-white font-bold rounded"
          onClick={() => appendToResult('8')}
        >
          8
        </button>
        <button
          className="p-4 bg-blue-500 text-white font-bold rounded"
          onClick={() => appendToResult('9')}
        >
          9
        </button>
        <button
          className="p-4 bg-blue-500 text-white font-bold rounded"
          onClick={() => appendToResult('0')}
        >
          0
        </button>
        <button
          className="p-4 bg-red-500 text-white font-bold rounded"
          onClick={() => cancelLastValue()}
        >
          CANCELLA
        </button>
      </div>
    </div>
  );
}

export default NumericPad;
