import axios from "axios";
import CookieService, { Cookies } from "./cookie.service";

export const baseHttp = axios.create({
  baseURL: CookieService.getApiUrl(),
  headers: {
    "Content-Type": "application/json",
  },
});

export const SetupInterceptors = (navigate: any, openNotificationWithIcon: any) => {
  // Add a request interceptor
  baseHttp.interceptors.request.use(
    config => {
      const token = CookieService.getToken(Cookies.TOKEN);
      if (token && token !== '') {
        config.headers['Authorization'] = 'Bearer ' + token
      }

      const divisionUUID = CookieService.getDivisionUUID();
      if (divisionUUID) {
        config.headers['auth-division'] = divisionUUID;
      }

      const baseUrl = CookieService.getApiUrl();
      if (baseUrl && baseUrl !== '') {
        config.baseURL = baseUrl;
      }
      // config.headers['Content-Type'] = 'application/json';
      return config
    },
    error => {
      Promise.reject(error)
    }
  )

  baseHttp.interceptors.response.use(
    function (response) {
      if (response.config.url?.includes('update')) {
        openNotificationWithIcon('success', 'SUCCESS')
      } else if (response.data.status === 400) {
        openNotificationWithIcon('error', response.data.message);
      }
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      if (error.response) {
        if (error.response.config.url?.includes('update')) {
          openNotificationWithIcon('error', "INTERNAL_SERVER_ERROR");
        }
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login');

        }
      }
      if (error.code === 'ERR_NETWORK') {
        openNotificationWithIcon('error', error.code)
      }
      return Promise.reject(error);
    }
  );
};



