import { atom } from "recoil";
import { ChecklistPhase } from "../../types/wrapper.types";

export const checklistPhase = atom({
    key: 'checklistPhase',
    default: [{
        uuid: '',
        po: 0,
        ca: 0,
        ci: 0,
        pa: 0
    }] as ChecklistPhase[],
});