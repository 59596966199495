import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { BrowserRouter, useNavigate } from "react-router-dom";
import Router from "./pages/router";
import { SetupInterceptors } from "./services/baseHttp";
import { notification } from "antd";
import { NotificationType } from "./types/general.types";
import { ConfigProvider } from "antd";
import { I18nextProvider } from "react-i18next";
import translation from "./translation";
import { language } from "./assets/translation/antTranslation";
import { locales } from "./assets/translation/antTranslation";
import { getColorByType, getMessageByType } from "./utils/message.utils";
import sixConfig from "./sixConfig";
import { useTranslation } from "react-i18next";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function NavigateFunctionComponent() {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [ran, setRan] = useState(false);
  const [api, contextHolder] = notification.useNotification({
    maxCount: 2,
  });
  const openNotificationWithIcon = (type: NotificationType, code: string) => {
    console.log(type);
    api[type]({
      message: `${t(code)}`,
      duration: ["error"].includes(type) ? 3 : 1,
    });
  };
  if (!ran) {
    SetupInterceptors(navigate, openNotificationWithIcon);
    setRan(true);
  }
  return <>{contextHolder}</>;
}

root.render(
  <I18nextProvider i18n={translation} defaultNS={["translation"]}>
    <ConfigProvider locale={locales[language.replace("-", "_")]}>
      <React.StrictMode>
        <RecoilRoot>
          <BrowserRouter basename={sixConfig.BASENAME}>
            {<NavigateFunctionComponent />}
            <Router />
          </BrowserRouter>
        </RecoilRoot>
      </React.StrictMode>
    </ConfigProvider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
