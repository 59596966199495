import { atom, selector } from "recoil";
import { PatientInfo } from "../../types/wrapper.types";

export const patientInfoState = atom({
    key: 'patientInfoState',
    default: {
        firstName: '',
        lastName: '',
        tin: '',
        birthDay: '',
        surgeryDate: '',
        uuid: ''
    } as PatientInfo,
});

export const getPatientInfoState = selector({
    key: 'getPatientInfoState', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        const value = get(patientInfoState);
        return value;
    },
});