import React, { useState, useEffect } from "react";
import "./Stopwatch.css";
import { Button, Space } from "antd";
import { Typography } from "antd";
import {
  PauseOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { DateTime } from "luxon";
const { Title } = Typography;

const Stopwatch = (payload: { rowId: number; setProps: any, data: any }) => {
  // state to store time
  const [time, setTime] = useState(payload.data.value);

  // state to check stopwatch running or not
  const [isRunning, setIsRunning] = useState(payload.data.isRunning);
  const [dateStarted, setDateStarted] = useState("");

  useEffect(() => {
    let intervalId: any = null;
    let updateId: any = null;

    if (isRunning) {
      // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
      intervalId = setInterval(() => setTime(time + 1), 10);
      updateId = setInterval(
        () =>
          payload.setProps({
            rowId: payload.rowId,
            isRunning: isRunning,
            dateStarted: dateStarted,
            value: time,
            now: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
          }),
        15
      );
    }else {
      payload.setProps({
        rowId: payload.rowId,
        isRunning: isRunning,
        value: time,
        now: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
      });
    }
    return () => {
      clearInterval(intervalId);
      clearInterval(updateId);
    };
  }, [isRunning, time]);

  // Hours calculation
  const hours = Math.floor(time / 360000);

  // Minutes calculation
  const minutes = Math.floor((time % 360000) / 6000);

  // Seconds calculation
  const seconds = Math.floor((time % 6000) / 100);

  // Milliseconds calculation
  const milliseconds = time % 100;

  // Method to start and stop timer
  const startAndStop = () => {
    if (!isRunning) {
      setDateStarted(DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"));
    }
    setIsRunning(!isRunning);
  };

  // Method to reset timer back to 0
  const reset = () => {
    setDateStarted("");
    setTime(0);
  };

  return (
    <>
      <div>
        <Space>
          <Title level={4}>
            {hours.toString().padStart(2, "0")}:
            {minutes.toString().padStart(2, "0")}:
            {seconds.toString().padStart(2, "0")}:
            {milliseconds.toString().padStart(2, "0")}
          </Title>
          <Button
            type="primary"
            icon={isRunning ? <PauseOutlined /> : <PlayCircleOutlined />}
            onClick={startAndStop}
          />
          <Button type="primary" icon={<ReloadOutlined />} onClick={reset} />
        </Space>
      </div>
    </>
  );
};

export default Stopwatch;
