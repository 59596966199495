import { AbnormalFilter } from "../types/common.types";
import { Implantable } from "../types/implantable.types";
import { baseHttp } from "./baseHttp";

const loadData = async (payload: { uuid: string; tabName: string }) => {
  return baseHttp
    .post(`/surgery-management-revision/last/revision/monitor`, payload)
    .then((response: any) => response.data);
};


const update = async (
  surgeryUUID: string,
  payload: {
    tabName: string;
    content: Array<Implantable>;
  }
) => {
  return baseHttp
    .post(`/surgery-management/monitor/update/${surgeryUUID}`, payload)
    .then((response: any) => response.data);
};


const getImplantables = async (
  payload: AbnormalFilter[],
  queryparams = {
    page: 1,
    take: 50,
    searchOn: "code,description",
    searchFor: "",
  }
) => {
  // post
  return baseHttp
    .post(`/article/filter`, payload, { params: queryparams })
    .then((response: any) => response.data);
};



const ImplantablesService = {
  loadData,
  getImplantables,
  update
}

export default ImplantablesService;