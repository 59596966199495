import { baseHttp } from "./baseHttp";
import { AbnormalFilter } from "../types/common.types";

const loadData = async (payload: AbnormalFilter[], page: number, pageSize: number, params: string) => {
    return baseHttp
        .post(`/surgery-management-revision-material/filter?q=1&take=${pageSize}&page=${page}${params}`, payload)
        .then((response: any) => response.data);
    };



const getMaterials = async (
    payload: AbnormalFilter[],
    queryparams = {
        page: 1,
        take: 50,
        searchOn: "code,description",
        searchFor: "",
    }
    ) => {
    // post
    return baseHttp
        .post(`/article/filter`, payload, { params: queryparams })
        .then((response: any) => response.data);
    };

const createMaterial = async (payload: any) => {
    return baseHttp
        .post(`/surgery-management-revision-material`, payload)
        .then((response: any) => response.data);
    };

const deleteMaterial = async (uuid: string) => {
    return baseHttp
        .delete(`/surgery-management-revision-material/${uuid}` )
        .then((response: any) => response.data);
    };

const update = async (uuid: String, payload: any) => {
    return baseHttp
        .put(`/surgery-management-revision-material/${uuid}`, payload)
        .then((response: any) => response.data);
    };

const MaterialsService = {
    loadData,
    getMaterials,
    update,
    createMaterial,
    deleteMaterial,
}

export default MaterialsService;