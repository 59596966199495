import "./Maintenance.css";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { CustomErrorsCode } from "../../enums/customErrors.enum";
import { imgHandler } from "../../ImgHandler";
import sixConfig from "../../sixConfig";

function Maintenance() {
  const { code } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState({code: "", title:"",  description: ""});
  const customer = sixConfig.APP_CUSTOMER;

  // get error message based on code
  const getErrorMessage = (code?: string) => {
    switch (code?.toUpperCase()) {
      case CustomErrorsCode.INDEXDB_UPGRADE_NEEDED:
        setError({code: CustomErrorsCode.INDEXDB_UPGRADE_NEEDED, title: "Oops", description: t('INDEXDB_UPGRADE_NEEDED')});
        break;
      default:
        setError({code: CustomErrorsCode.CLIENT_ERROR, title: "Oops",  description: t('CLIENT_ERROR')});
    }
  };

  React.useEffect(() => {
    getErrorMessage(code);
  }, []);

  return (
    <>  
        <div style={{backgroundColor: 'white'}}>
            <div className="mainBox">
              <div className="errorPageLeft">
              <img src={require(`../../assets/images/${imgHandler?.[customer]?.src_login_top}.jpg`)} className="imageLogo" alt="" />
              </div>
              <div className="borderRight"></div>
            </div>
            
            <div className="errorPageRight">
              <span className="writtenBig">{error.title}</span>
              <span className="writtenSmall">{error.code} - {error.description}</span>
            </div>
        </div>
    </>
  );
}

export default Maintenance;
