import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { language } from './assets/translation/antTranslation';

const translation = i18n.createInstance();

translation.use(XHR).init({
  debug: true,
  fallbackLng: `${window.location.origin}/translation/en.json`,
  backend: {
    loadPath: `${window.location.origin}/translation/${language.slice(0, 2)}.json?t=`+ new Date().getTime(),
  },
  lng: language.slice(0, 2),
});
    
export default translation;