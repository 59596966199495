import { atom, selector } from "recoil";
import { SharedInternalInfo } from "../../types/wrapper.types";

export const sharedInternalInfoState = atom({
    key: 'sharedInternalInfoState',
    default: {
        surgery: '',
        checklists: {
            po: false,
            ca: false,
            ci: false,
            pa: false
        },
        status: 'confirmed'
    } as SharedInternalInfo,
});

export const getSharedInternalInfoState = selector({
    key: 'getSharedInternalInfoState', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        const value = get(sharedInternalInfoState);
        return value;
    },
});